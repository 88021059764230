<div style=" margin-top: 100px;">
  <section class="content-cont">
    <div *ngIf="receptId == 1" class="contenido">
      <h1>{{wi}} <div class="adorn"></div>
      </h1>
      <ul>
        <li>
          Si el pago es con cheque, el envío NO se hará hasta que hayan transcurrido 48 horas y se haya reflejado el
          pago.
        </li>
        <li>
          Si el método de pago se realizó por transferencia bancaria, el envío se hará hasta que hayan transcurrido 24
          horas después de haber enviado tu comprobante
          de pago.
        </li>
        <li>
          Para pago con TRANSFERENCIA ó en OXXO tiene solo 3 días para realizar su pago y enviar su comprobante al
          correo contacto&#64;lideart.com.mx y ventas&#64;lideart.com.mx
          indicando la orden de venta de su pedido que este empieza con L-OV00... en el asunto del correo, en caso de no
          recibir su pago al 4to día le llegara un correo confirmando
          la cancelación de su pedido, y en caso de aun querer el pedido será necesario volverlo a realizar.
          Te recordamos que para proceder con el envío de tu compra, es necesario recibir el comprobante de pago
          correspondiente en caso de haber realizado una transferencia bancaria o pago en OXXO. Si no contamos con el
          comprobante, no podremos realizar el envío de tu pedido.
        </li>
        <li>
          Todos los pedidos hechos en días hábiles antes de las 14:00 hrs. salen el mismo día, después de esa hora, el
          envío se hace al día siguiente hábil.
        </li>
        <li>
          Es necesario presentar su credencial de elector a la hora de recoger su mercancía en sucursal.
        </li>
        <li>
          En el caso poco probable que un equipo o mercancía saliera defectuosa, por favor contáctese al correo
          contacto&#64;lideart.com.mx y siga las instrucciones
          que le indique el colaborador de nuestra compañía.
        </li>
        <li>
          Favor de validar sus datos fiscales, no se realizarán refacturaciones por error de envío de datos.
        </li>
        <li>
          Favor de indicar al momento de su compra si requieren factura fiscal proporcionando los datos necesarios para
          tal acción, de lo contrario no se podrá
          refacturar la mercancía.
        </li>
        <li>
          Estimado Cliente, le recordamos que para poder facturar con nosotros debe estar dado de alta ante el SAT. De
          lo contrario se le realizará la venta como
          “Público en General” con los precios establecidos.
        </li>
        <li>
          En caso de que el paquete sea rechazado por parte del cliente, él mismo deberá cubrir el costo de la guía
          usado para el regreso del paquete.
        </li>


        <li>
          Las paqueterías son externas a Lideart por lo que les pedimos que si al recibir su paquete va con algún golpe,
          con sellos de nuestra cinta violados,
          cajas rotas, cajas húmedas o con algún tipo de daño que pueda comprometer o no la mercancía que va dentro les
          pedimos que por precaución, se haga la
          anotación al firmar de recibido con los comentarios del estado de la caja. Les pedimos confirmarnos dicha
          información en un lapso de no mayor a 24 hrs,
          al correo contacto&#64;lideart.com.mx adjuntando fotografías o video, para en caso de que al abrir su caja
          encuentren algún daño en su mercancía, podamos reportarlo
          a paquetería oportunamente, favor de indicar la referencia de su pedido en el asunto del correo (9 letras
          indicadas en este mismo correo).
        </li>

        <li>
          Los tiempos de entrega son estimados de 5 a 6 días hábiles según la temporada, en temporada como hot sales,
          buen fin y época decembrina los tiempos de entrega pueden ser de hasta 10 o 15 días hábiles para la entrega de
          tu paquete.
        </li>
        <li>
          De acuerdo con nuestra política de devoluciones, no será posible realizar la devolución de productos que se
          encuentren en perfectas condiciones para su uso. Esto incluye productos que no presenten defectos de
          fabricación ni daños visibles, y que hayan sido correctamente manipulados y almacenados. En caso de que el
          producto cumpla con estas condiciones, no se aceptarán devoluciones, ya que se considera que está en
          condiciones aptas para su uso conforme a su propósito original.
        </li>
      </ul>
      <div class="info-cont">
        <span>INFORMACIÓN DE CONTACTO</span>
        <span>Whatsapp: (614) 605-1278</span>
        <span>Tel: (614) 4-32-61-22 Ext: 1142</span>
        <span>Correo: ventas&#64;lideart.com.mx</span>
      </div>
      <div class="cont-btn">
        <button class="btn-print" (click)="print()">Imprimir esta página</button>
      </div>
    </div>
    <div *ngIf="receptId == 2" class="contenido">
      <h1>{{wi}} <div class="adorn"></div>
      </h1>
      <div class="spans-cont">
        <span>Información requerida para proceder con devoluciones de dinero:</span>
        <span>- Monto de la devolución</span>
        <span>- Motivo de la devolución</span>
        <span>- Comprobante de pago</span>
        <span>- Caratula de estado de cuenta que contenga: Nombre del beneficiario de la cuenta (debe ser la misma
          cuenta de la cual procede el pago), número de cuenta,
          clabe interbancaria (18 dígitos) y nombre del banco en el que se realizará el depósito.</span>
        <span>- POR DISPOSICIONES FISCALES Y POLÍTICAS DE LA EMPRESA, <b>no se efectuarán reembolsos a clientes a una
            cuenta distinta a la procedente de los recursos.</b></span>
        <span>- Su información personal se procesa y almacena en servidores o medios magnéticos que mantienen altos
          estándares de seguridad y protección tanto física
          como tecnológica.</span>
      </div>
      <ul>
        <li>
          Para cualquier situación referente a faltante de material, error en el surtido o error en su pedido, cuenta
          solo con 72 horas hábiles para reportarnos la
          situación, la cual estará sujeta a revisión.
        </li>
        <li>
          Cuando devuelve un artículo, acepta que el reembolso y la forma en que se acredita pueden variar de acuerdo
          con la condición del artículo, el tiempo que ha
          tenido el artículo y la forma en que este fue comprado. Si devuelve un producto diferente o en condiciones
          diferentes al que enviamos, no podremos procesar
          su reembolso.
        </li>
        <li>
          El producto debe venir en su empaque original y en las mismas condiciones que le fue enviado. Al devolver el
          producto debe proteger tanto el producto como
          el empaque del mismo para evitar daños en transporte.
        </li>
        <li>
          En material cortado NO habrá devoluciones ni cambios.
        </li>
        <li>
          Contamos con 24 horas para realizar las revisiones.
        </li>
        <li>
          No hay cambios inmediatos.
        </li>
        <li>
          Se cuentan con 72 horas hábiles para realizar cualquier devolución.
        </li>
        <li>
          Todos los materiales de uso y soporte del producto (incluyendo herramientas, manuales, tarjetas de garantía,
          etc.) deben ser devueltos con el artículo.
        </li>
        <li>
          Códigos de licencias o tarjetas electrónicas no se pueden devolver después de la compra.
        </li>
        <li>
          En caso de que se tenga que aplicar algún reembolso por medio del método de pago CONEKTA se debe de esperar un
          mínimo de 30 días hábiles.
        </li>
        <li>
          De acuerdo con nuestra política de devoluciones, no será posible realizar la devolución de productos que se
          encuentren en perfectas condiciones para su uso. Esto incluye productos que no presenten defectos de
          fabricación ni daños visibles, y que hayan sido correctamente manipulados y almacenados. En caso de que el
          producto cumpla con estas condiciones, no se aceptarán devoluciones, ya que se considera que está en
          condiciones aptas para su uso conforme a su propósito original.
        </li>
      </ul>
      <div class="cont-btn">
        <button class="btn-print" (click)="print()">Imprimir esta página</button>
      </div>
    </div>
    <div *ngIf="receptId == 3" class="contenido">
      <h1>{{wi}} <div class="adorn"></div>
      </h1>
      <div class="spans-cont">
        <span class="taitl">Los datos que recabamos</span>
        <span>- Lo primero que se debe hacer para disfrutar de los servicios de Lideart Innovación es inscribirse,
          suministrando ciertos datos personales
          ("Datos Personales") completos y exactos. Podremos solicitar, recabar y almacenar los siguientes Datos
          Personales: apodo o seudónimo para operar en el sitio de
          Lideart, nombre, número de documento o identificación válida, información física de contacto (como número de
          teléfono domicilio, dirección de e-mail, etc.).
          Lideart no recabará de sus usuarios datos personales sensibles tales como datos religiosos, sindicales,
          raciales, étnicos, etc. Lideart podrá confirmar los
          Datos Personales suministrados acudiendo a entidades públicas, compañías especializadas o centrales de riesgo,
          para lo cual nos autorizas expresamente a
          compartir esos datos, incluyendo datos financieros y/o patrimoniales, con las mencionadas entidades. La
          información que Lideart obtenga de estas entidades
          será tratada en forma confidencial.</span>
        <span>- El Usuario que se registre en Lideart a través de su cuenta personal en una red social ("Cuenta
          Personal"), o de cualquier modo acceda a Lideart a través
          de su Cuenta Personal, consiente expresamente que Lideart:</span>
        <span>- Tenga acceso, en cualquier momento, a la totalidad de la información contenida en su Cuenta Personal,
          incluyendo en particular, pero sin limitación, a sus
          Datos Personales, información sobre sus intereses, gustos, contactos y cualquier otro contenido alojado en su
          Cuenta Personal;</span>
        <span>- Incluya en dicha cuenta, mensajes, fotografías, vídeos y cualquier otro tipo de contenido; y envíe al
          correo electrónico del Usuario vinculado a la Cuenta
          Personal, información o mensajes con la finalidad descrita en la sección "Finalidad del tratamiento de los
          Datos Personales" abajo.</span>
        <span>- Se acuerda expresamente que en cualquier momento el usuario registrado en Lideart podrá solicitar la
          baja o cierre de su solicitud de registración, y/o
          el cierre de su cuenta o eliminación de su cuenta e información de la base de datos de Lideart. Por favor
          revise la sección "Medios para ejercer los derechos
          de acceso, cancelación y rectificación de los Datos Personales" abajo.</span>
        <span>- Lideart recoge y almacena automáticamente cierta información sobre la actividad de los usuarios y
          visitantes dentro de su sitio web. Tal información
          puede incluir la URL de la que provienen (estén o no en nuestro sitio web), a qué URL acceden seguidamente
          (estén o no en nuestro sitio web), qué navegador
          están usando, y sus direcciones IP. También las páginas visitadas, las búsquedas realizadas, las
          publicaciones, compras o ventas, calificaciones y réplicas
          ingresadas, mensajes en los foros, entre otra información podrá ser almacenada y retenida.</span>
        <span>- Lideart accede a las listas de contactos de los dispositivos móviles utilizados por los usuarios para
          proveer sus servicios. Esa información la utilizará
          únicamente para localizar y señalar números de teléfonos celulares y/o correos electrónicos de otros usuarios.
          A su vez, los usuarios tienen autorización para
          compartir dicha información con Lideart.</span>
        <span>- En cuanto a los potenciales usuarios, Lideart no almacenará nombres, direcciones postales, datos
          bancarios, números de cédula de identidad y/o documentos
          de identidad, ni ninguna otra información que permita identificar o haga identificable a una persona. Solo
          almacenará números de teléfonos celulares y correos
          electrónicos.</span>
        <span>- Si los usuarios nos envían correspondencia sean correos electrónicos o cartas, o si otros usuarios o
          terceros nos envían correspondencia sobre las
          actividades o sobre los mensajes de otros usuarios en nuestro sitio, podemos recoger y almacenar tales Datos
          Personales, incluyendo direcciones de e-mail.</span>
      </div>
      <div class="spans-cont">
        <span class="taitl">Finalidad del tratamiento de los Datos Personales</span>
        <span>- Para suministrar un excelente servicio y para que los usuarios puedan realizar operaciones en forma ágil
          y segura, Lideart requiere cierta información de
          carácter personal, incluyendo dirección de e-mail. La recolección de información nos permite ofrecer a los
          usuarios servicios y funcionalidades que se adecuen
          mejor a sus necesidades y personalizar nuestros servicios para hacer que sus experiencias con Lideart sean lo
          más cómodas posible. Los Datos Personales que
          recabamos se destinan a las siguientes finalidades:</span>
        <span>- Ayudar al comprador y vendedor a entrar en contacto directo en la oportunidad que corresponda según la
          modalidad de la compra. En este caso, Lideart
          suministrará a los interesados sus Datos Personales (nombre, teléfonos, localidad y correo electrónico), a
          través de correo electrónico o a través del sitio.
          La información así conocida por el comprador o el vendedor, sólo podrá ser utilizada a efectos de concluir la
          operación originada en Lideart y no deberá ser
          empleada por ninguno de ellos con fines publicitarios o promocionales u otras actividades no relacionadas con
          Lideart, salvo la expresa autorización del
          usuario.</span>
        <span>- Desarrollar estudios internos sobre los intereses, comportamientos y demografía de los usuarios para
          comprender mejor sus necesidades e intereses y ofrecer
          mejores servicios o proveerles información relacionada.</span>
        <span>- Mejorar nuestras iniciativas comerciales y promocionales y analizar las páginas visitadas las búsquedas
          realizadas por los usuarios, para mejorar nuestra
          oferta de contenidos y artículos, personalizar dichos contenidos, su presentación y servicios.</span>
        <span>- Enviar información o mensajes por e-mail sobre nuevos servicios, mostrar publicidad o promociones,
          banners, de interés para nuestros usuarios, noticias
          sobre Lideart, además de la información expresamente autorizada en la sección de preferencias. Si el usuario
          lo prefiere, puede solicitar que lo excluyan de
          las listas para el envío de información promocional o publicitaria. Por favor aprende a hacerlo la sección
          "Cambio de preferencias de e-mail" abajo.</span>
        <span>- Enviar información o avisos por mensajes cortos de texto (SMS) al teléfono celular suministrado por el
          usuario a Lideart.</span>
        <span>- Los ganadores de las Promociones y/o concursos realizados por Lideart autorizan expresamente a difundir
          sus nombres, Datos Personales e imágenes y los de
          sus familias, por los medios y en las formas que consideren convenientes, con fines publicitarios y/o
          promocionales, sin derecho a compensación alguna.</span>
        <span>- Compartir los Datos Personales (incluyendo dirección de e-mail e información patrimonial y/o financiera)
          con los proveedores de servicios o las empresas
          de "outsourcing" que contribuyan a mejorar o facilitar las operaciones a través de Lideart, como (sin
          limitarse a) servicios de transporte, medios de pago,
          seguros o intermediarios en la gestión de pagos, call centers o programas de fidelidad, entidades públicas y/o
          privadas que brindan información crediticia,
          entre otros. Lideart velará porque se cumplan ciertos estándares, mediante la firma de acuerdos o convenios
          cuyo objeto sea la privacidad de los Datos
          Personales de nuestros usuarios. No obstante, Lideart no se hace responsable por el uso indebido de los Datos
          Personales del usuario que hagan estas
          compañías o sitios de Internet. En algunos casos, estos proveedores de servicios serán quienes recojan
          información directamente del usuario (por ejemplo
          si les solicitamos que realicen encuestas o estudios). En tales casos, podrá recibir una notificación acerca
          de la participación de un proveedor de
          servicios en tales actividades, y quedará a discreción del usuario toda la información que quiera brindarle y
          los usos adicionales que los proveedores
          decidan hacer. En caso de que facilites, por propia iniciativa información adicional a dichos prestadores de
          servicios directamente, tales Prestadores
          usarán esta información conforme a sus propias políticas de privacidad. Si decidimos revelar o compartir Datos
          Personales con terceros que no son proveedores
          de servicios o empresas afiliadas o relacionadas con Lideart, requeriremos su consentimiento.</span>
        <span>- Suministrar los Datos Personales de los usuarios a las entidades que intervengan en la resolución de
          disputas entre los mismos, tales como: Compañías
          de Seguros, Amigables Componedores o Tribunales de Arbitraje o tribunales competentes para solucionar tales
          disputas.</span>
      </div>
      <div class="spans-cont">
        <span class="taitl">Confidencialidad de los Datos Personales</span>
        <span>- Una vez inscrito en su sitio Web, Lideart no venderá, alquilará o compartirá los Datos Personales
          excepto en las formas establecidas en este Aviso de
          Privacidad. Sin perjuicio de ello, el usuario consiente en forma expresa que Lideart transfiera total o
          parcialmente los Datos Personales a cualquiera de las
          sociedades controladas, controlantes y/o vinculadas con Lideart, a cualquier título y en el momento, forma y
          condiciones que estime pertinentes. Haremos todo
          lo que esté a nuestro alcance para proteger la privacidad de la información. Puede suceder que en virtud de
          órdenes judiciales, o de regulaciones legales,
          nos veamos compelidos a revelar información a las autoridades o terceras partes bajo ciertas circunstancias, o
          bien en casos que terceras partes puedan
          interceptar o acceder a cierta información o transmisiones de datos en cuyo caso Lideart no responderá por la
          información que sea revelada.</span>
      </div>
      <div class="spans-cont">
        <span class="taitl">Seudónimo</span>
        <span>- Para interactuar dentro del sitio, los usuarios deben utilizar un seudónimo que los identificará. Los
          usuarios no tendrán acceso a los Datos Personales
          de los otros usuarios, salvo en el caso de los ofertantes de una Subasta Exclusiva y cuando hayan realizado
          con ellos alguna operación a través de nuestra
          plataforma, luego de la cual ambas partes recibirán la información de la otra.</span>
      </div>
      <div class="spans-cont">
        <span class="taitl">Clave Personal</span>
        <span>- Para acceder a los servicios reservados únicamente para los usuarios debidamente inscritos los usuarios
          dispondrán de una clave personal. Los usuarios
          deben mantener esta clave bajo absoluta confidencialidad y, en ningún caso, deberán revelarla o compartirla
          con otras personas.</span>
        <span>- El usuario será responsable de todos los actos que tengan lugar mediante el uso de su Seudónimo y Clave,
          lo que incluye hacerse cargo del pago de las
          tarifas que eventualmente se devenguen o por los perjuicios que puedan sufrir otros usuarios por tal
          motivo.</span>
      </div>
      <div class="spans-cont">
        <span class="taitl">Menores de Edad</span>
        <span>- Nuestros servicios sólo están disponibles para aquellas personas que tengan capacidad legal para
          contratar. Por lo tanto, aquellos que no cumplan con
          esta condición deberán abstenerse de suministrar Datos Personales para ser incluidos en nuestras bases de
          datos. Sin embargo, pueden hacerlo a través de los
          padres o tutores.</span>
      </div>
      <div class="spans-cont">
        <span class="taitl">El uso de los Datos Personales por otros Usuarios</span>
        <span>- Para facilitar la interacción entre todos los miembros de la comunidad de Lideart, nuestro servicio
          permite un acceso limitado a ciertos datos de
          contacto del resto de usuarios, tales como Nombre de usuario, Teléfonos, Ciudad y dirección de correo
          electrónico.</span>
        <span>- Los usuarios sólo podrán utilizar los Datos Personales de otros usuarios obtenidos en el sitio para: (a)
          comunicaciones relacionadas con Lideart que no
          constituyan comunicaciones comerciales no solicitadas, (b) utilizar servicios ofrecidos en Lideart (por
          ejemplo: depósito, seguros, envío o transporte y
          reclamaciones sobre fraude), y (c) cualquier otra finalidad a la que el usuario correspondiente consienta
          expresamente una vez le hayan sido comunicadas
          previamente la información legalmente requerida.</span>
        <span>- Bajo ninguna circunstancia, se debe comunicar Datos Personales o dirección de correo electrónico de otro
          usuario a ningún tercero sin nuestro consentimiento
          y el del usuario afectado. No se debe agregar a la agenda de direcciones de correo electrónico (física o
          electrónica) los datos de ningún usuario de Lideart,
          ni siquiera los datos de quienes hayan adquirido algún artículo ofrecido en Lideart, sin que medie el
          consentimiento expreso de tal usuario.</span>
      </div>
      <div class="spans-cont">
        <span class="taitl">Cookies</span>
        <span>- Se establece que la instalación, permanencia y existencia de las Cookies en el computador del usuario
          depende de su exclusiva voluntad y puede ser eliminada
          de su computador cuando el usuario así lo desee. Para saber cómo quitar las Cookies del sistema es necesario
          revisar la sección Ayuda (Help) del navegador.</span>
        <span>- Adicionalmente, se pueden encontrar Cookies u otros sistemas similares instalados por terceros en
          ciertas páginas de nuestro sitio Web. Por ejemplo,
          al navegar por una página creada por un usuario, puede que exista una Cookie emplazada en tal página.</span>
        <span>- Por último, Lideart almacena cookies para poder ofrecer una experiencia más interactiva en el sitio,
          basada en las acciones del usuario. El usuario puede,
          en cualquier momento, eliminar las cookies almacenadas, configurar el navegador para que solicite aprobación
          antes de almacenar cookies o directamente evitar
          que se almacenen cookies.</span>
        <span>- Este procedimiento se realiza de forma diferente en los distintos navegadores y es necesario realizarlo
          en cada navegador que se utilice. A continuación
          ponemos a disposición los diferentes procedimientos para manejar esta configuración en los navegadores más
          utilizados:</span>
        <span>- Microsoft Windows Explorer:
          https://support.microsoft.com/es-ar/help/17442/windows-internet-explorer-delete-manage-cookies</span>
        <span>- Google Chrome: https://support.google.com/chrome/answer/95647?hl=es&p=cpn_cookies</span>
        <span>- Mozilla Firefox:
          https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias</span>
        <span>- Apple Safari: https://support.apple.com/kb/PH21411?viewlocale=es_US&locale=es_US</span>
        <span>- Se aclara expresamente que este Aviso de Privacidad cubre la utilización de Cookies por este sitio y no
          la utilización de Cookies por parte de anunciantes.
          Nosotros no controlamos el uso de Cookies por terceros.</span>
      </div>
      <div class="spans-cont">
        <span class="taitl">Spam</span>
        <span>- Lideart y sus usuarios no aceptan conductas consideradas "spamming", ya sea en opiniones, preguntas,
          respuestas y/o el envío no solicitado de correos electrónicos.
          Queda absolutamente prohibido el envío indiscriminado de mensajes de cualquier naturaleza entre los usuarios
          de Lideart.</span>
        <span>- Lideart podrá suspender o inhabilitar definitivamente a aquellos usuarios que violen esta
          política.</span>
      </div>
      <div class="spans-cont">
        <span class="taitl">Orden de autoridades competentes - Requerimientos Legales</span>
        <span>- Lideart coopera con las autoridades competentes y con otros terceros para garantizar el cumplimiento de
          las leyes, por ejemplo en materia de protección de derechos
          de propiedad industrial e intelectual, prevención del fraude y otras materias.</span>
        <span>- Lideart puede (y los usuarios lo autorizan expresamente) comunicar cualquier Dato Personal sobre sus
          usuarios con la finalidad de cumplir la normativa
          aplicable y cooperar con las autoridades competentes en la medida en que discrecionalmente lo entendamos
          necesario y adecuado en relación con cualquier
          investigación de un ilícito o un fraude, infracción de derechos de propiedad industrial o intelectual, u otra
          actividad que sea ilegal o que pueda exponer a
          Lideart o a sus usuarios a cualquier responsabilidad legal. Además, podemos (y los usuarios lo autorizan
          expresamente) comunicar su nombre completo, seudónimo,
          domicilio, ciudad, región, código postal, país, número de teléfono, dirección de correo electrónico, etc. a
          los participantes en el Programa de Protección de
          Propiedad Intelectual de Lideart en la forma que, a nuestra discreción, entendamos necesaria o adecuada en
          relación con la investigación del fraude, infracción
          de derechos de propiedad industrial o intelectual, piratería, o cualquier otra actividad ilegal. Este derecho
          será ejercido por Lideart a efectos de cooperar
          con el cumplimiento y ejecución de la ley, independientemente que no exista una orden judicial o
          administrativa al efecto.</span>
        <span class="taitl">Seguridad y medios para evitar el uso o divulgación de los Datos Personales. Almacenamiento
          y transferencia de los Datos Personales</span>
        <span>- Lideart está obligado a cumplir con toda la normativa aplicable en materia de medidas de seguridad
          aplicables a los Datos Personales. Adicionalmente,
          Lideart usa los estándares de la industria entre materia de protección de la confidencialidad de sus Datos
          Personales, incluyendo, en otras medidas,
          cortafuegos ("firewalls") y Secure Socket Layers ("SSL"). Lideart considera a los datos de sus usuarios como
          un activo que debe ser protegido de cualquier
          pérdida o acceso no autorizado. Empleamos diversas técnicas de seguridad para proteger tales datos de accesos
          no autorizados por usuarios de dentro o fuera
          de nuestra compañía. Sin embargo, es necesario tener muy en cuenta que la seguridad perfecta no existe en
          Internet.</span>
        <span>- El usuario de Lideart conoce y acepta expresamente que Lideart, a su exclusivo criterio, recabe,
          almacene y monitoree el intercambio de mensajes y
          correos electrónicos entre sus usuarios, efectuado dentro de la comunidad de Lideart, con el objetivo de
          contribuir a la seguridad de las relaciones y
          comunicaciones en la comunidad.</span>
        <span>- Por ello, Lideart no se hace responsable por interceptaciones ilegales o violación de sus sistemas o
          bases de datos por parte de personas no autorizadas.
          Lideart, tampoco se hace responsable por la indebida utilización de la información obtenida por esos
          medios.</span>
        <span>- Todos los Datos Personales de los usuarios serán almacenados en un fichero automatizado de datos
          personales.</span>
      </div>
      <div class="spans-cont">
        <span class="taitl">Cambio en las preferencias de e-mails</span>
        <span>- Sin perjuicio que Lideart quiere mantener a sus usuarios actualizados en todo momento sobre promociones,
          novedades, cambios, etc. los usuarios pueden
          seleccionar los e-mails e información promocional que gustarían recibir de Lideart.</span>
        <span>- Si no quiere recibir correos electrónicos, puede des-suscribirte, cambiando tus preferencias de e-mail
          siguiendo las instrucciones que proporcionamos
          en nuestras comunicaciones o bien accediendo a su cuenta en la sección Lideart. En esa sección podrás
          seleccionar las preferencias para que sean tenidas en
          cuenta por Lideart en las futuras comunicaciones o enviando tus preferencias por correo a la dirección postal
          indicada en este Aviso de Privacidad.</span>
      </div>
      <div class="spans-cont">
        <span class="taitl">Procedimiento para comunicar los cambios al Aviso de Privacidad</span>
        <span>- Lideart podrá modificar en cualquier momento los términos y condiciones de este Aviso de Privacidad y
          confidencialidad y/o las prácticas de envío de
          e-mails. Si decidimos introducir algún cambio material a nuestro Aviso de Privacidad, te notificaremos
          publicando una versión actualizada del Aviso de Privacidad
          en esta sección o mediante el envío de un e-mail o informándolo en la página principal u otras secciones del
          sitio para mantenerte actualizado de los cambios
          realizados.</span>
        <span>- Si hacemos cambios materiales en la forma que sus Datos Personales son administrados te notificaremos
          por e-mail para que puedas tomar una decisión
          informada respecto si aceptas o no que tus Datos Personales sean utilizados de esa forma. Si no acepta esos
          términos, en ese caso quedará disuelto el
          vínculo contractual y sus Datos Personales no serán usados de otra forma que la que fue informada al momento
          de recabarse.</span>
      </div>
      <div class="cont-btn">
        <button class="btn-print" (click)="print()">Imprimir esta página</button>
      </div>
    </div>
    <div *ngIf="receptId == 4" class="contenido">
      <h1>{{wi}} <div class="adorn"></div>
      </h1>
      <ul>
        <li>
          Los servicios de paquetería se dará a elegir por parte del comprador entre las opciónes de PaqueteExpress y
          Estafeta Estándar a excepción de envíos sin
          costo cumpliendo con los requerimientos necesarios de compra de pedidos mayores a $2,000 MXN.
        </li>
        <li>
          El costo de envío varía de entre $165.45 MXN hasta $434.57 MXN dependiendo de la cantidad y/o peso del pedido
          que se generó por parte del cliente.
        </li>
        <li>
          El plazo de entrega es de entre 3 a 7 días hábiles una vez confirmada la orden de compra.
        </li>
        <li>
          Si el pago es con cheque, el envío NO se hará hasta que hayan transcurrido 48 horas y se haya reflejado el
          pago.
        </li>
        <li>
          Si el método de pago se realizó por transferencia bancaria, el envío se hará hasta que hayan transcurrido 24
          horas después de haber enviado tu comprobante de pago.
        </li>
      </ul>
      <div class="spans-cont">
        <span>Estimado cliente es importante que si usted sospecha que el paquete este dañado no lo reciba y se
          comunique de inmediato con la paquetería para
          hacer la reclamación. En el momento que firma de recibido y el paquete es abierto se invalida cualquier
          garantía por parte del remitente.</span>
      </div>
    </div>
    <div *ngIf="receptId == 5" class="contenido">
      <h1>{{wi}} <div class="adorn"></div>
      </h1>
      <div class="spans-cont">
        <span>La garantía de los productos adquiridos con Lideart se brindaran a través del sitio WEB o a los contactos
          de Lideart publicados en el mismo según la
          garantía que especifique cada uno de los productos en su sección, donde se especifican condiciones de uso
          adecuado del producto y el alcance de la garantía,
          por lo cual el comprador / usurario no podrá solicitar una garantía con mayor alcance a la que ahí se
          especifica.</span>
        <span>En caso de que se aplique la garantía a un equipo, te informamos que no es posible realizar un cambio por
          otro equipo diferente. El reemplazo solo será posible por un equipo de la misma categoría, sujeto a
          disponibilidad.</span>
        <span>De acuerdo con nuestra política de devoluciones, no será posible realizar la devolución de productos que
          se encuentren en perfectas condiciones para su uso. Esto incluye productos que no presenten defectos de
          fabricación ni daños visibles, y que hayan sido correctamente manipulados y almacenados. En caso de que el
          producto cumpla con estas condiciones, no se aceptarán devoluciones, ya que se considera que está en
          condiciones aptas para su uso conforme a su propósito original.</span>
      </div>
      <div class="spans-cont">
        <span class="taitl">Garantía no aplica a consumibles (incluído navajas ni hojas transportadoras).</span>
        <span>El proceso para la garantía se empieza en la revisión de videos que le serán solicitados al usuario para
          evaluar el problema en el área de soporte de Lideart
          y las condiciones de uso general de los equipos, si se detecta una falla que no se pueda solucionar por medio
          de soporte en línea, se solicitara el envío del
          equipo a las instalaciones de Lideart, los costos de envío correrán a cargo del usuario. Si se determina que
          el equipo o producto tiene la falla por falta de
          mantenimiento o usos no indicados para el mismo los cargos de reparación, limpieza, revisión y envío correrán
          a cuenta del usurario. Si se detecta falla de
          fábrica del producto, Lideart se hace responsable de entregar el equipo funcionando sin ningún cargo para el
          usuario. Lideart solo se hace responsable de los
          costos de flete de envío al usuario si es por defecto de fábrica, de lo contrario también deberá cubrirlos el
          usuario.</span>
      </div>
      <div class="spans-cont">
        <span class="taitl">Garantía general Craftroom.</span>
        <span>1 año de garantía directa con Craftroom, garantía valida únicamente en defectos de fábrica.</span>
        <span>No aplica en:</span>
      </div>
      <ul>
        <li>
          En consumibles tales como navajas, tapetes, plumillas o cualquier otra herramienta.
        </li>
        <li>
          Rodillos.
        </li>
        <li>
          Palancas
        </li>
        <li>
          Choques eléctricos.
        </li>
      </ul>
      <div class="spans-cont">
        <span>Se anula la garantía:</span>
      </div>
      <ul>
        <li>
          Si se utilizar tapetes o navajas que no sean de LA MARCA.
        </li>
        <li>
          Falta de mantenimiento y limpieza correcta.
        </li>
        <li>
          Uso incorrecto del equipo.
        </li>
        <li>
          Si se usa adhesivo reposicionable en el tapete de corte (este deja residuos internos y externos en el equipo).
        </li>
        <li>
          Si se retiran o modificación de las etiquetas de identificación de la maquina o de sus piezas.
        </li>
      </ul>
      <a href="assets/downloads/Documento-garantias-general-Craftroom.pdf" class="download" target="blank">Descargar
        Documento PDF Garantías Craftroom</a>
      <div class="spans-cont">
        <span class="taitl">Planchas planas, planchas de tazas, plancha de gorras, combo 6 en 1</span>
        <span>Garantía válida por 6 meses directamente con Lideart.</span>
      </div>
      <ol>
        <li>No aplica en partes de desgaste tales como resistencias.</li>
        <li>No aplica si el equipo no está protegido con un regulador de voltaje.</li>
        <li>No aplica en caso de choques eléctricos.</li>
        <li>No aplica la garantía en caso de encontrar la falla por un mal uso del operador.</li>
        <li>Se anula la garantía por falta de mantenimiento y limpieza correcta del equipo.</li>
        <li>Es necesario que el cliente levante el reporte de la falla ante el departamento de soporte técnico.</li>
        <li>Soporte técnico asesora telefónicamente y en caso de que se necesario solicitará el envío del equipo a
          Chihuahua.</li>
        <li>El costo de reparación así como piezas de la misma serán cubiertos por el fabricante del equipo.</li>
        <li>La garantía no cubre gastos de traslado del equipo.</li>
        <li>Toda garantía deberá ser registrada en el formulario correspondiente antes de proceder a cualquier
          reparación.</li>
        <li>Queda exento de toda garantía las partes de desgaste</li>
        <li>Esta garantía es válida únicamente en la República Mexicana.</li>
      </ol>
      <div class="spans-cont">
        <span class="taitl">Pinzas Acribend</span>
        <span>Garantía válida por 1 año directamente con Lideart.</span>
      </div>
      <ol>
        <li>No aplica en partes de desgaste tales como resistencias.</li>
        <li>No aplica en caso de choques eléctricos.</li>
        <li>No aplica la garantía en caso de encontrar la falla por un mal uso del operador.</li>
        <li>Se anula la garantía por falta de mantenimiento y limpieza correcta del equipo.</li>
        <li>Es necesario que el cliente levante el reporte de la falla ante el departamento de soporte técnico.</li>
        <li>Soporte técnico asesora telefónicamente y en caso de que se necesario solicitará el envío del equipo a
          Chihuahua.</li>
        <li>El costo de reparación así como piezas de la misma serán cubiertos por el fabricante del equipo.</li>
        <li>La garantía no cubre gastos de traslado del equipo.</li>
        <li>Toda garantía deberá ser registrada en el formulario correspondiente antes de proceder a cualquier
          reparación.</li>
        <li>Queda exento de toda garantía las partes de desgaste</li>
        <li>Esta garantía es válida únicamente en la República Mexicana.</li>
      </ol>
      <div class="spans-cont">
        <span class="taitl">Máquina de coser</span>
        <span>Garantía Válida por 1 mes directamente con Lideart.</span>
      </div>
      <ol>
        <li>Se tendrá que presentar la póliza anexada en el equipo, debidamente llenada y sellada, junto con copia de
          factura y/o comprobante de compra con el producto en el centro de servicio donde fue adquirido.
          Nota: el paso anterior lo realizara la Empresa al momento de la devolución del cliente.</li>
        <li>El tiempo de reparación no será mayor de 30 días contados a partir de la fecha de recepción del producto en
          cualquiera de los Centros de Servicio autorizados
          JANOME, en caso de que el producto necesite alguna refacción no disponible en México el periodo máximo de
          entrega será de 60 días.</li>
        <li>Esta garantía no es aplicable en refacciones, componentes o accesorios, cuando estos presenten daños
          atribuidos al mal uso del cliente y no por defectos de
          fabricación</li>
        <li>Esta garantía es válida únicamente en la República Mexicana.</li>
        <li>Los fletes no están incluidos en los servicios que amparan la garantía.</li>
        <li>No aplica la garantía en caso de encontrar la falla por un mal uso del operador.</li>
        <li>Se anula la garantía por falta de mantenimiento y limpieza correcta del equipo.</li>
      </ol>
      <div class="spans-cont">
        <span class="taitl">Impresoras Zebra</span>
        <span>Garantía válida por 1 año directamente con Lideart.</span>
      </div>
      <ol>
        <li>No aplica en caso de choques eléctricos</li>
        <li>Es necesario que el cliente levante el reporte de la falla ante el departamento de soporte técnico.</li>
        <li>Soporte técnico asesora telefónicamente y en caso de que se necesario solicitará el envío del equipo a
          Chihuahua.</li>
        <li>El costo de reparación así como piezas de la misma serán cubiertos por el fabricante del equipo.</li>
        <li>La garantía no cubre gastos de traslado del equipo.</li>
        <li>Toda garantía deberá ser registrada en el formulario correspondiente antes de proceder a cualquier
          reparación.</li>
        <li>Queda exento de toda garantía las partes de desgaste</li>
        <li>Esta garantía es válida únicamente en la República Mexicana.</li>
        <li>No aplica la garantía en caso de encontrar la falla por un mal uso del operador.</li>
        <li>Se anula la garantía por falta de mantenimiento y limpieza correcta del equipo.</li>
      </ol>
      <div class="spans-cont">
        <span class="taitl">Fotobotones</span>
        <span>Garantía válida por 6 meses directamente con Lideart.</span>
      </div>
      <ul>
        <li>No aplica en partes de desgaste tales como palanca y perno de sujeción.</li>
        <li>No aplica en caso de no seguir el procedimiento correcto de elaboración de fotobotones indicado en la página
          web de Lideart, así como las precauciones de uso
          de la máquina.</li>
        <li>Es necesario que el cliente levante el reporte de la falla ante el departamento de soporte técnico.</li>
        <li>No aplica la garantía en caso de encontrar la falla por un mal uso del operador.</li>
        <li>Se anula la garantía por falta de mantenimiento y limpieza correcta del equipo.</li>
        <li>Soporte técnico asesora telefónicamente y en caso de que se necesario solicitará el envío del equipo a
          Chihuahua.</li>
        <li>El costo de reparación así como piezas de la misma serán cubiertos por el fabricante del equipo.</li>
        <li>La garantía no cubre gastos de traslado del equipo.</li>
        <li>Toda garantía deberá ser registrada en el formulario correspondiente antes de proceder a cualquier
          reparación.</li>
        <li>Esta garantía es válida únicamente en la República Mexicana.</li>
      </ul>
      <div class="spans-cont">
        <span class="taitl">Silhouette (CAMEO, CURIO, PORTRAIT Y MINT)</span>
        <span>Garantía válida por 1 año directamente con Lideart.</span>
      </div>
      <ol>
        <li>No aplica en partes de desgaste tales como navajas, rodillos, palancas, etc.</li>
        <li>Se anula la garantía si se utiliza adhesivo reposicionable en las hojas transportadoras de corte.</li>
        <li>No aplica la garantía en caso de encontrar la falla por un mal uso del operador.</li>
        <li>Se anula la garantía por falta de mantenimiento y limpieza correcta del equipo.</li>
        <li>No aplica en caso de choques eléctricos.</li>
        <li>Es necesario que el cliente levante el reporte de la falla ante el departamento de soporte técnico.</li>
        <li>Soporte técnico asesora telefónicamente y en caso de que se necesario solicitará el envío del equipo a
          Chihuahua.</li>
        <li>El costo de reparación así como piezas de la misma serán cubiertos por el fabricante del equipo.</li>
        <li>La garantía no cubre gastos de traslado del equipo.</li>
        <li>Toda garantía deberá ser registrada en el formulario correspondiente antes de proceder a cualquier
          reparación.</li>
        <li>Esta garantía es válida únicamente en la República Mexicana.</li>
      </ol>
      <div class="spans-cont">
        <span class="taitl">Silhouette Alta</span>
        <span>Garantía valida por 1 año directamente con Lideart y 1000 horas en extrusor.</span>
      </div>
      <ol>
        <li>No aplica en partes de desgaste como cintas, plataformas, etc.</li>
        <li>No aplica la garantía en caso de encontrar la falla por un mal uso del operador.</li>
        <li>Se anula la garantía por falta de mantenimiento y limpieza correcta del equipo.</li>
        <li>No aplica en caso de choques eléctricos.</li>
        <li>Es necesario que el cliente levante el reporte de la falla ante el departamento de soporte técnico.</li>
        <li>Soporte técnico asesora telefónicamente y en caso de que se necesario solicitará el envío del equipo a
          Chihuahua.</li>
        <li>El costo de reparación así como piezas de la misma serán cubiertos por el fabricante del equipo.</li>
        <li>La garantía no cubre gastos de traslado del equipo.</li>
        <li>Toda garantía deberá ser registrada en el formulario correspondiente antes de proceder a cualquier
          reparación.</li>
        <li>Esta garantía es válida únicamente en la República Mexicana.</li>
      </ol>
      <div class="spans-cont">
        <span class="taitl">Laminadora para foil</span>
        <span>Garantía válida por 6 meses directamente con Lideart.</span>
      </div>
      <ol>
        <li>No aplica en caso de choques eléctricos.</li>
        <li>Es necesario que el cliente levante el reporte de la falla ante el departamento de soporte técnico.</li>
        <li>No aplica la garantía en caso de encontrar la falla por un mal uso del operador.</li>
        <li>Se anula la garantía por falta de mantenimiento y limpieza correcta del equipo.</li>
        <li>Soporte técnico asesora telefónicamente y en caso de que se necesario solicitará el envío del equipo a
          Chihuahua.</li>
        <li>El costo de reparación así como piezas de la misma serán cubiertos por el fabricante del equipo.</li>
        <li>La garantía no cubre gastos de traslado del equipo.</li>
        <li>Toda garantía deberá ser registrada en el formulario correspondiente antes de proceder a cualquier
          reparación.</li>
        <li>Esta garantía es válida únicamente en la República Mexicana.</li>
      </ol>
      <div class="spans-cont">
        <span class="taitl">Pistola de calor Embossing</span>
        <span>Garantía valida por 1 año directamente con Lideart.</span>
      </div>
      <ol>
        <li>La garantía únicamente se aplica por defectos de fábrica.</li>
        <li>No aplica la garantía en caso de encontrar la falla por un mal uso del operador.</li>
        <li>Se anula la garantía por falta de mantenimiento y limpieza correcta del equipo.</li>
        <li>No aplica en caso de choques eléctricos.</li>
        <li>Es necesario que el cliente levante el reporte de la falla ante el departamento de soporte técnico.</li>
        <li>Soporte técnico asesora telefónicamente y en caso de que se necesario solicitará el envío del equipo a
          Chihuahua.</li>
        <li>El costo de reparación así como piezas de la misma serán cubiertos por el fabricante del equipo.</li>
        <li>La garantía no cubre gastos de traslado del equipo.</li>
        <li>Toda garantía deberá ser registrada en el formulario correspondiente antes de proceder a cualquier
          reparación.</li>
        <li>Esta garantía es válida únicamente en la República Mexicana.</li>
      </ol>
      <div class="spans-cont">
        <span class="taitl">Garantía CRICUT</span>
        <span>Causas que invalidan la garantía:</span>
      </div>
      <ul>
        <li>Utilizar adhesivo removible en los tapetes</li>
        <li>Utilizar tapetes o navajas que no sean de LA MARCA</li>
        <li>Alterar los equipos en cualquier forma</li>
        <li>Uso inadecuado del equipo</li>
        <li>Golpes en el equipo</li>
        <li>Descargas eléctricas (partes eléctricas quemadas)</li>
        <li>Modificaciones físicas del equipo</li>
        <li>Retiro o modificación de las etiquetas de identificación de la maquina o de sus piezas</li>
        <li>Desensamblar cualquier componente de la maquina</li>
        <li>Exposición a temperaturas mayores a 110°F</li>
        <li>Utilizar cables de corriente eléctrica o USB que no sean los originales del equipo</li>
      </ul>
      <div class="spans-cont">
        <span>La garantía se limita al precio de venta del equipo, sin incluir ningún costo adicional, tal como,</span>
      </div>
      <ul>
        <li>Perdida o daño de cualquier material usado en la maquina</li>
        <li>Reclamaciones por daños de terceras personas</li>
        <li>Daños indirectos, incidentales, o especiales, o por cualquier daño que tenga consecuencias económicas</li>
        <li>Perdida de ganancias, ingresos comerciales, ahorros previstos o renombre comercial.</li>
        <li>La garantía no aplica,</li>
        <li>para accesorios como ensamblajes de navajas, repuestos de navajas, software, materiales, herramientas ni
          ningún otro elemento que no corresponda al equipo directamente, así como tampoco incluye para partes de
          desgaste o reemplazables por el cliente como tapetes de corte, etc.</li>
        <li>El ensamblaje de la cuchilla y las cuchillas de corte, ya sea que hayan sido proporcionadas con la Máquina o
          vendidas en forma independiente de la Máquina;</li>
        <li>Las almohadillas de corte, ya sea que hayan sido proporcionadas con la Máquina o vendidas en forma
          independiente de la Máquina;</li>
        <li>Desperfectos que resulten de un uso incorrecto (incluyendo, pero no en forma exclusiva, un uso que supere
          las capacidades o habilidades de la Máquina), de su uso comercial o para propósitos comerciales, de su uso con
          software no autorizado, de un accidente, de modificaciones, de su uso en un entorno físico u operativo
          inapropiado, o de la realización de un mantenimiento incorrecto; y</li>
        <li>Desperfectos causados por un producto por el cual LA MARCA no es responsable.</li>
      </ul>
      <div class="spans-cont">
        <span>Ninguna otra persona/entidad podrá modificar esta garantía limitada, y su vendedor será el único
          responsable por cualquier otra garantía.</span>
        <span>Pueden requerirse pruebas para hacer valida la garantía</span>
      </div>
    </div>
  </section>
</div>