<section class="content-cont-ad" style=" margin-top: 100px;">
    <h1 class="taitl">Direcciones</h1>
    <div class="centre">
        <div class="carda" *ngFor="let item of direccion">
            <div class="pric-cont">
                <i class="fa-solid fa-location-dot fa-bounce"></i>
                <span class="til">{{item.address1}} {{item.address2}}</span>
            </div>
            <span class="texta">{{item.firstname}} {{item.lastname}}</span>
            <span class="texta">{{item.alias}}</span>
            <!-- <span class="texta">{{item.company}}</span> -->
            <!-- <span class="texta">{{item.phone}}</span> -->
            <span class="texta">Mexico</span>
            <span class="texta">{{item.nombreEstado}}</span>
            <span class="texta">{{item.city}}</span>
            <span class="texta">{{item.colony}}</span>
            <div class="btns-cont">
                <button class="del" (click)="borrame(item.id_address)">Borrar</button>
                <button class="mod" (click)="traeDatosDireccion(item.id_address)">Modificar</button>
            </div>
        </div>

        <!-- <div class="carda">
          <div class="pric-cont">
              <i class="fa-solid fa-location-dot fa-bounce"></i>
              <span class="til">Aqui va la dirección #92381</span>
          </div>
          <span class="texta">Aqui va nombre</span>
          <span class="texta">Codigo postal</span>
          <span class="texta">no recuerdo</span>
          <span class="texta">pais</span>
          <span class="texta">mas</span>
          <span class="texta">mas</span>
          <span class="texta">mas</span>
          <div class="btns-cont">
              <button class="del">Borrar</button>
              <button class="mod">Modificar</button>
          </div>
      </div> -->
    </div>
    <!-- <a class="and">Agregar Nueva Dirección</a> -->
    <div class="buttons">
        <div class="pull-right">
            <!-- I have read and agree to the <a href="#" class="agree"><b>Pricing Tables</b></a>
                  <input class="box-checkbox" type="checkbox" name="agree" value="1"> &nbsp; -->
            <input value="Agregar Nueva Dirección" type="button" (click)="handleWP2()" class="btn btn-primary">
        </div>
    </div>
    <a [routerLink]="['/perfil',0]" routerLinkActive="router-link-active" class="atras">Volver</a>
</section>


<div class="modal-c-wp" #modal_wp id="modal_wp">
    <div class="btn-wp-cont">
        <button class="btn-wp-close" (click)="handleWP()">x</button>
    </div>
    <form [formGroup]="direccionesForm" class="form-horizontal account-register clearfix"
        style="padding: 15px; display: flex; flex-wrap: wrap; gap: 20px;">

        <div class="form-group" style="flex: 1 1 30%;">
            <label class="control-label" for="input-company">Alias de Dirección</label>
            <div>
                <input type="text" name="alias" formControlName="alias" placeholder="Alias de Dirección"
                    class="form-control">
            </div>
            <div class="col-sm-10">
                <p class="text-muted small">Ej. Mi casa</p>
            </div>
        </div>

        <div class="form-group required" style="flex: 1 1 30%;">
            <label class="control-label" for="address1">Calle y Número Exterior</label>
            <div>
                <input type="text" name="address1" formControlName="address1" placeholder="Ingresa calle y numero"
                    class="form-control">
            </div>
            <div class="col-sm-10">
                <p class="text-muted small">Ej. Calle ficticia #95284</p>
            </div>
        </div>

        <div class="form-group required" style="flex: 1 1 30%;">
            <label class="control-label" for="zipcode">Codigo Postal</label>
            <div>
                <input type="text" name="zipcode" formControlName="zipcode" placeholder="Ingresa Codigo Postal"
                    class="form-control"
                    (change)="buscameXCodigoPostal(this.direccionesForm.controls['zipcode'].value)">
            </div>
            <div class="col-sm-10">
                <p class="text-muted small">Ej. 01010</p>
            </div>
        </div>

        <div class="form-group required" style="flex: 1 1 30%;">
            <label class="control-label" for="input-zone">Provincia o Estado</label>
            <div class="col-sm-10">
                <select name="id_state" class="form-control" formControlName="stateValue" style="height: auto;">
                    <option [value]="ciudadsinCodigo" selected>{{ciudadsinCodigo}}</option>
                </select>
            </div>
        </div>

        <div class="form-group required" style="flex: 1 1 30%;">
            <label class="control-label" for="input-country">Ciudad</label>
            <div class="col-sm-10">
                <select name="city" class="form-control" formControlName="cityValue" style="height: auto;">
                    <option [value]="ciudadSeleccionada" selected>{{ciudadSeleccionada}}</option>
                </select>
            </div>
        </div>

        <div class="form-group required" style="flex: 1 1 30%;">
            <label class="control-label" for="input-country">Colonia</label>
            <div>
                <select name="colony" class="form-control" style="height: auto;" formControlName="colonyValue"
                    (change)="imprimir()">
                    <option [value]="nombreColonia" selected>{{nombreColonia}}</option>
                    <option *ngFor="let item of arrayColonias" value="{{item.colonia+'-'+item.countyId}}">
                        {{item.colonia}}</option>
                </select>
            </div>
        </div>

        <div class="form-group required" style="flex: 1 1 30%;">
            <label class="control-label" for="phonenumber">Telefono</label>
            <div>
                <input type="text" name="phonenumber" formControlName="phonenumber" placeholder="Ingresa Telefono"
                    class="form-control" (keypress)="allowOnlyNumbers($event)">
                <span *ngIf="direccionesForm.controls['phonenumber'].errors?.['pattern']" class="text-danger small">
                    Número de teléfono inválido. Favor de ingresar 10 dígitos.
                </span>
            </div>
            <div class="col-sm-10">
                <p class="text-muted small">Ej. 5512345678</p>
            </div>
        </div>

        <div class="form-group" style="flex: 1 1 30%;">
            <label class="control-label" for="address2">Delegación</label>
            <div>
                <input type="text" name="address2" formControlName="address2" placeholder="Complementa tu dirección"
                    class="form-control">
            </div>
        </div>

        <div class="buttons" style="flex: 1 1 100%; display: flex; justify-content: flex-end;">
            <input value="Guardar" type="button" (click)="guardaDireccion()" class="btn btn-primary">
        </div>
    </form>
</div>


<div class="modal-c-wp" #modal_wp2 id="modal_wp2">
    <div class="btn-wp-cont">
        <button class="btn-wp-close" (click)="handleWP2()">x</button>
    </div>
    <form [formGroup]="direccionesForm" class="form-horizontal account-register clearfix"
        style="padding: 15px; display: flex; flex-wrap: wrap; gap: 20px;">

        <div class="form-group" style="flex: 1 1 30%;">
            <label class="control-label" for="input-company">Alias de Dirección</label>
            <div>
                <input type="text" name="alias" formControlName="alias" placeholder="Alias de Dirección"
                    class="form-control">
            </div>
            <div class="col-sm-10">
                <p class="text-muted small">Ej. Mi casa</p>
            </div>
        </div>

        <div class="form-group required" style="flex: 1 1 30%;">
            <label class="control-label" for="address1">Calle y Número Exterior</label>
            <div>
                <input type="text" name="address1" formControlName="address1" placeholder="Ingresa calle y numero"
                    class="form-control">
            </div>
            <div class="col-sm-10">
                <p class="text-muted small">Ej. Calle ficticia #95284</p>
            </div>
        </div>

        <div class="form-group required" style="flex: 1 1 30%;">
            <label class="control-label" for="zipcode">Codigo Postal</label>
            <div>
                <input type="text" name="zipcode" formControlName="zipcode" placeholder="Ingresa Codigo Postal"
                    class="form-control"
                    (change)="buscameXCodigoPostal(this.direccionesForm.controls['zipcode'].value)">
            </div>
            <div class="col-sm-10">
                <p class="text-muted small">Ej. 01010</p>
            </div>
        </div>

        <div class="form-group required" style="flex: 1 1 30%;">
            <label class="control-label" for="input-zone">Provincia o Estado</label>
            <div class="col-sm-10">
                <select name="id_state" class="form-control" formControlName="stateValue" style="height: auto;">
                    <option [value]="ciudadsinCodigo" selected>{{ciudadsinCodigo}}</option>
                </select>
            </div>
        </div>

        <div class="form-group required" style="flex: 1 1 30%;">
            <label class="control-label" for="input-country">Ciudad</label>
            <div class="col-sm-10">
                <select name="city" class="form-control" formControlName="cityValue" style="height: auto;">
                    <option [value]="ciudadSeleccionada" selected>{{ciudadSeleccionada}}</option>
                </select>
            </div>
        </div>

        <div class="form-group required" style="flex: 1 1 30%;">
            <label class="control-label" for="input-country">Colonia</label>
            <div>
                <select name="colony" class="form-control" formControlName="colonyValue" (change)="imprimir()"
                    style="height: auto;">
                    <option [value]="nombreColonia" selected>{{nombreColonia}}</option>
                    <option *ngFor="let item of arrayColonias" value="{{item.colonia+'-'+item.countyId}}">
                        {{item.colonia}}</option>
                </select>
            </div>
        </div>

        <div class="form-group required" style="flex: 1 1 30%;">
            <label class="control-label" for="phonenumber">Teléfono</label>
            <div>
                <input type="text" name="phonenumber" formControlName="phonenumber" placeholder="Ingresa Telefono"
                    class="form-control" (keypress)="allowOnlyNumbers($event)">
                <span *ngIf="direccionesForm.controls['phonenumber'].errors?.['pattern']" class="text-danger small">
                    Número de teléfono inválido. Favor de ingresar 10 dígitos.
                </span>
            </div>
            <div class="col-sm-10">
                <p class="text-muted small">Ej. 5512345678</p>
            </div>
        </div>

        <div class="form-group" style="flex: 1 1 30%;">
            <label class="control-label" for="address2">Delegación</label>
            <div>
                <input type="text" name="address2" formControlName="address2" placeholder="Complementa tu dirección"
                    class="form-control">
            </div>
        </div>

        <div class="buttons" style="flex: 1 1 100%; display: flex; justify-content: flex-end;">
            <input value="Guardar" type="button" (click)="nuevitaDireccion()" class="btn btn-primary">
        </div>
    </form>
</div>

<div class="loader" *ngIf="cargando">
    <img src="/assets/img/loadersillo.gif">
</div>