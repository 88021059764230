import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Inject, Injectable, PLATFORM_ID, signal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Producto } from '../Models/Productos';
import { Usuario } from '../Models/usuario';
import { CheckoutServiceService } from './checkout-service.service';
import { LoginService } from './login.service';
import { TiktokEventsService } from './tiktok-events.service';
import { CartData, ValidarInvService } from './validar-inv.service';

const httpOption = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root'
})
export class CarritoServiceService {
  private _isLoadingCarritoData = signal(false);

  public isLoadingCarritoData() {
    return this._isLoadingCarritoData();
  }

  placeholder: any[] = [];
  //TODO: Cambiar el BehaviorSubject por un signal
  cartItems = new BehaviorSubject<CartData[]>([]); //? Este es el verdadeo carrito
  cartItems2 = new BehaviorSubject<any>([]);
  cartItems3 = new BehaviorSubject<any>([]);

  itemscarrito: any = [];
  agarraitems: any;
  carrito: any;
  cuantostengo: any;
  usuario!: Usuario | null;
  constructor(
    public http: HttpClient,
    private cartService: CheckoutServiceService,
    private tiktokEvents: TiktokEventsService,
    private usuarioService: LoginService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {



    if (isPlatformBrowser(this.platformId)) {


      this.usuarioService.usuario.subscribe((res: Usuario | null) => {
        this.usuario = res;
        this.getCartData()



        setTimeout(() => {

          const lastRegarga = localStorage.getItem("recargado2");
          if (window.caches && lastRegarga == null) {

            localStorage.setItem("recargado2", '1')
            caches.keys().then(names2 => { names2.forEach(name2 => { caches.delete(name2); }); });
            window.location.reload()
          }

          if (!localStorage.getItem("borrado2")) {
            localStorage.setItem('cart', "[]");
            localStorage.setItem("borrado2", "1")
          }


          if (this.usuario) {
            const ls = this.carrito;
            const ls2 = this.getCartData2();
            const ls3 = this.getCartData3();
            if (ls) {
              this.cartItems.next(ls);
            }
            if (ls2) {
              this.cartItems2.next(ls2);
            }
            if (ls3) {
              this.cartItems3.next(ls3);
            }
          }

          else {
            const ls = this.getCartData();

            const ls2 = this.getCartData2();
            const ls3 = this.getCartData3();
            if (ls) {
              this.cartItems.next(ls);
            }
            if (ls2) {
              this.cartItems2.next(ls2);
            }
            if (ls3) {
              this.cartItems3.next(ls3);
            }


          }


        }, 2000);
      })

    }
  }



  addItem(product: Producto) {

    if (this.usuario) {

      this.tiktokEvents.sendEvent({
        event: 'AddToCart',
        value: product.precio
      });

      const ls = this.carrito;
      let exist: any;
      let pasa: any;

      pasa = false;

      if (ls) {
        ls.find((item: any) => {

          if (!pasa) {

            pasa = (item.sku === product.sku);
            if (pasa) {
              exist = item;
            }

          }
        });
        if (pasa) {
          exist.quantity += product.quantity;
          this.setCartData(ls);

        } else {
          ls.push(product);
          this.setCartData(ls);
        }
      } else {

        const newData = [product];
        this.placeholder.push(product);
        this.setCartData(newData);

      }

    } else {
      this.tiktokEvents.sendEvent({
        event: 'AddToCart',
        value: product.precio
      });

      const ls = this.getCartData();
      let exist: any;
      let pasa: any;

      pasa = false;

      if (ls) {
        ls.find((item: any) => {

          if (!pasa) {

            pasa = (item.sku === product.sku);
            if (pasa) {
              exist = item;
            }

          }
        });
        if (pasa) {
          exist.quantity += product.quantity;
          this.setCartData(ls);

        } else {
          ls.push(product);
          this.setCartData(ls);
        }
      } else {

        const newData = [product];
        this.placeholder.push(product);
        this.setCartData(newData);

      }
    }


  }

  addItem2(product: Producto) {

    this.tiktokEvents.sendEvent({
      event: 'AddToCart',
      value: product.precio
    });

    const ls = this.getCartData2();
    let exist: any;
    let pasa: any;

    pasa = false;

    if (ls) {
      ls.find((item: any) => {

        if (!pasa) {

          pasa = (item.sku === product.sku);
          if (pasa) {
            exist = item;
          }

        }
      });
      if (pasa) {
        exist.quantity += product.quantity;
        this.setCartData2(ls);

      } else {
        ls.push(product);
        this.setCartData2(ls);
      }
    } else {

      const newData = [product];
      this.placeholder.push(product);
      this.setCartData2(newData);

    }
  }


  addItem3(product: Producto) {

    this.tiktokEvents.sendEvent({
      event: 'AddToCart',
      value: product.precio
    });

    const ls = this.getCartData3();
    let exist: any;
    let pasa: any;

    pasa = false;

    if (ls) {
      ls.find((item: any) => {

        if (!pasa) {

          pasa = (item.sku === product.sku);
          if (pasa) {
            exist = item;
          }

        }
      });
      if (pasa) {
        exist.quantity += product.quantity;
        this.setCartData3(ls);

      } else {
        ls.push(product);
        this.setCartData3(ls);
      }
    } else {

      const newData = [product];
      this.placeholder.push(product);
      this.setCartData3(newData);

    }
  }

  setCartData2(data: any) {
    localStorage.setItem("mesa_regalos", JSON.stringify(data));
    this.cartItems2.next(this.getCartData2());
  }


  setCartData3(data: any) {
    localStorage.setItem("item_regalo", JSON.stringify(data));
    this.cartItems3.next(this.getCartData3());
  }

  setCartData(data: any) {
    const currentUrl = window.location.href;
    if (this.usuario) {
      localStorage.setItem("cart", JSON.stringify(data));
      try {
        const jsonCarrito = {
          email: this.usuario ? this.usuario.usuario : "",
          carrito_guardado: JSON.stringify(data)
        };
        //! Esto se puede manejar sin tener que recargar la pagina!
        this.cartService.registraCarrito2(jsonCarrito).subscribe((res) => {
          this.cartItems.next(this.getCartData());

          setTimeout(() => {
            if (!currentUrl.includes('checkout')) location.reload();
          }, 2000);
        });
      } catch (error) {
        this.cartItems.next(this.getCartData());
      }
    } else {
      localStorage.setItem("cart", JSON.stringify(data));
      this.cartItems.next(this.getCartData());
    }
  }

  //TEMP
  private _valCarrito = inject(ValidarInvService)

  getCartData(): any[] {
    //! El llamato al metodo "addStockInfo" es redundante porque no permite mejorarlo el que se rotorne inmediatamente el carrito en esos 3 casos
    //!TODO: Tambien este meotodo deberia ser asincrono (En caso que se dese retornar algo), ya que puediese evitarse obtener el carrito desde aqui y hacerlo directamente del attributo. (Probablemnte con un signal)
    this._isLoadingCarritoData.set(true);
    const carritoLocal = JSON.parse(localStorage.getItem("cart") || "[]");
    if (this.usuario) {
      try {
        this.cartService.getCartData(this.usuario.usuario).subscribe({
          next: (resssd) => {
            console.log("getCartData", resssd);
            this.itemscarrito = resssd;
            this.carrito = JSON.parse(this.itemscarrito[0].carrito_guardado || []);
            this._valCarrito.addStockInfo(this.carrito || []).then(resp => {
              console.log('Carrito, STOCK:', resp)
              this.cartItems.next(resp);
              this.carrito = resp;
              this._isLoadingCarritoData.set(false);
            });
          },
          error: (err) => {
            console.log("getCartData", err);
            this._valCarrito.addStockInfo(carritoLocal || []).then(resp => {
              console.log('Carrito, STOCK:', resp)
              this.cartItems.next(resp);
              this.carrito = resp;
              this._isLoadingCarritoData.set(false);
            });
          }
        });
        return this.carrito || [];
      } catch (error) {
        this._valCarrito.addStockInfo(carritoLocal).then(resp => {
          console.log('Carrito, STOCK:', resp)
          this.cartItems.next(resp);
          this.carrito = resp;
          this._isLoadingCarritoData.set(false);
        });
        this._isLoadingCarritoData.set(false);
        return carritoLocal; // Devuelve un array vacío si hay un error
      }
    } else {
      this._valCarrito.addStockInfo(carritoLocal).then(resp => {
        console.log('Carrito, STOCK:', resp)
        this.cartItems.next(resp);
        this.carrito = resp;
        this._isLoadingCarritoData.set(false);
      });
      this._isLoadingCarritoData.set(false);
      return carritoLocal; // Devuelve un array vacío si no hay usuario
    }
  }

  getCartData2() {
    return JSON.parse(localStorage.getItem("mesa_regalos") + "");
  }

  getCartData3() {
    return JSON.parse(localStorage.getItem("item_regalo") + "");
  }
  deleteCart() {
    const currentUrl = window.location.href;
    if (this.usuario) {
      this.cartService.actualizaNuevoCarrito(this.usuario.usuario).subscribe(respuesta => {
        this.getCartData();
        if (!currentUrl.includes('checkout')) location.reload();
      })
    } else {
      this.placeholder = [];
      this.cartItems.next(this.placeholder);
      localStorage.removeItem('cart');
    }

  }

  deleteCart2() {
    this.placeholder = [];
    this.cartItems2.next(this.placeholder);
    localStorage.removeItem('mesa_regalos');
  }


  deleteCart3() {
    this.placeholder = [];
    this.cartItems3.next(this.placeholder);
    localStorage.removeItem('item_regalo');
  }



  registraNuevoCarrito(email: any) {
    return this.http.post<Response>('https://new.lideart.com.mx/Automatizacion/apis/carritoCreado.php', email, httpOption);
  }

  agregaCarrito(datosCarro: any) {
    return this.http.post('https://new.lideart.com.mx/Automatizacion/apis/agregaCarrito.php', datosCarro);
  }

  updateCarrito(datosCarro: any) {
    return this.http.post('https://new.lideart.com.mx/Automatizacion/apis/updateCarrito.php', datosCarro);
  }

  getCarritoAgregado(correo: string) {
    return this.http.get<Response>('https://new.lideart.com.mx/Automatizacion/apis/getCarritoAgregado.php?email=' + correo);

  }

  carritoPasoCaja(email: any) {
    return this.http.post<Response>('https://new.lideart.com.mx/Automatizacion/apis/carritoPasoCaja.php', email, httpOption);
  }

  carritoPasoUsuario(email: any) {
    return this.http.post<Response>('https://new.lideart.com.mx/Automatizacion/apis/carritoPasoUsuario.php', email, httpOption);
  }

  carritoPasoDireccion(email: any) {
    return this.http.post<Response>('https://new.lideart.com.mx/Automatizacion/apis/carritoPasoDireccion.php', email, httpOption);
  }

  carritoPasoEnvio(email: any) {
    return this.http.post<Response>('https://new.lideart.com.mx/Automatizacion/apis/carritoPasoEnvio.php', email, httpOption);
  }

  carritoFinalizo(email: any) {
    return this.http.post<Response>('https://new.lideart.com.mx/Automatizacion/apis/carritoFinalizo.php', email, httpOption);
  }


}
