import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ApiBuscadorGoogleService } from '../../services/api-buscador-google.service';
import { HandleInpSearchService } from '../../services/handle-inp-search.service';
import { Item, searchResult } from '../../types/googleSearch';

@Component({
  selector: 'app-google-search',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    RouterModule
  ],
  templateUrl: './google-search.component.html',
  styleUrls: ['./google-search.component.scss'],
})
export class GoogleSearchComponent implements OnInit {

  searchQuery!: string | any;


  query: string = '';
  results: searchResult = {
    kind: "",
    url: {
      type: "",
      template: ""
    },
    queries: {
      request: [
        {
          title: "",
          totalResults: "",
          searchTerms: "",
          count: 0,
          startIndex: 0,
          inputEncoding: "",
          outputEncoding: "",
          safe: "",
          cx: ""
        }
      ],
      nextPage: [], // Inicializa nextPage como un array vacío
      previousPage: []
    },
    context: {
      title: ""
    },
    searchInformation: {
      searchTime: 0,
      formattedSearchTime: "",
      totalResults: "",
      formattedTotalResults: ""
    },
    items: []
  };
  startIndex: number = 1;
  sortOrder: string = '';
  resultsPerPage: number = 10;
  currentPage: number = 1;
  isTodos: boolean = false;
  resultsPerPageForSelect: number = 10;
  showAllColors: boolean = false;
  coloresMostrados: string[] = [];
  imagesLoaded: boolean = false;
  allItems: Item[] = [];

  @ViewChild('focus', { read: ElementRef }) tableInput!: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private txtBuscadorService: HandleInpSearchService,
    private router: Router,
    public customSearchService: ApiBuscadorGoogleService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit(): void {
    /*this.route.queryParams.subscribe(params => {
      this.searchQuery = params['query'];
      this.performSearch(this.searchQuery);
    });*/
    /*this.txtBuscadorService.txtBuscador$.subscribe((txtb) => {
      this.searchQuery = txtb;
      this.performSearch(this.searchQuery);
    });*/

    // this.txtBuscadorService.txtBuscador$.subscribe((txtb) => {
    // loquetenia = txtb
    // if (loquetenia != traielbuscador) {
    //   this.searchQuery = traielbuscador
    // }else{
    //   this.searchQuery = loquetenia
    // }
    this.route.params.subscribe(params => {
      this.searchQuery = params['busqueda']
      this.performSearch(this.searchQuery);
    })
    this.currentPage = 1;

    // this.searchQuery = sessionStorage.getItem('searchQuery');

    // if (this.searchQuery) {

    //   this.performSearch(this.searchQuery!);
    // }
    // });

    // Handle back navigation
    if (isPlatformBrowser(this.platformId)) {
      window.addEventListener('popstate', (event) => {
        const initialUrl = sessionStorage.getItem('initialUrl');
        if (initialUrl && this.router.url === '/google-search/' + this.query) {
          sessionStorage.removeItem('initialUrl');
          this.router.navigateByUrl(initialUrl); // Navigate back to the initial URL
        }
      });
    }
  }

  performSearch(query: string): void {
    if (query) {
      this.currentPage = 1;
      this.customSearchService.realSearch(query).subscribe(response => {
        this.allItems = response.items || []; // Asegurarse de que allItems sea un arreglo
        this.updatePage();
      });
    }
  }

  onSearch(): void {
    this.performSearch(this.query);
  }

  updatePage(): void {
    const startIndex = (this.currentPage - 1) * this.resultsPerPage;
    const endIndex = startIndex + this.resultsPerPage;
    this.results.items = this.allItems.slice(startIndex, endIndex);
  }

  nextPage(): void {
    if ((this.currentPage * this.resultsPerPage) < this.allItems.length) {
      this.currentPage++;
      this.updatePage();
    }
  }

  prevPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.updatePage();
    }
  }

  onResultsPerPageChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    const value = parseInt(selectElement.value, 10);

    if (value === 0) {
      // Mostrar todos los resultados
      this.resultsPerPage = this.allItems.length;
    } else {
      this.resultsPerPage = value;
    }

    this.currentPage = 1;
    this.updatePage();
  }

  scrollUp(): void {
    setTimeout(() => {
      if (this.tableInput && this.tableInput.nativeElement) {
        this.tableInput.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else {
        console.warn('tableInput o nativeElement no están definidos');
      }
    });
  }

  private extractPath(url: string): string {
    try {
      const parsedUrl = new URL(url);
      return `/${parsedUrl.pathname}`;
    } catch (error) {
      return url;
    }
  }

  toggleShowColors(event: MouseEvent, link: string): void {
    event.stopPropagation();
    event.preventDefault();
    if (this.coloresMostrados.includes(link)) {
      this.coloresMostrados = this.coloresMostrados.filter(itemLink => itemLink !== link);
    } else {
      this.coloresMostrados.push(link);
    }
  }

  onImagesLoaded() {
    this.imagesLoaded = true;
  }
}