import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

//! Este servicio esta llamado aqui de forma HOT-FIX. Esto debe de llamarse desde el back-end y solo en ciertas ocasiones (informacion visual) desde el front-end.


export interface InputData {
  products: InputProduct[]
}

export interface InputProduct {
  qty: number
  id: string
  sku: string
  unitId: string
  isPaquete: boolean
  depth: number
  height: number
  weight: number
  width: number
}

export interface OutputProduct extends InputProduct {
  qtyDisp: number
  isAvaible: boolean
}

export interface CartData {
  existencia: number
  sku: string
  id_product: string
  id: string
  image_link: string
  unitId: string
  link_rewrite: string
  meta_title: string
  meta_description: string
  active: string
  activo: string
  tags: any[]
  color: string
  alto: string
  ancho: string
  largo: string
  peso: string
  category_rewrite: string
  quantity: number
  precio: number
  jsonExistencias: string
  nombreMuestra: string
  id_category?: string
  name?: string
  weight?: string
  width?: string
  height?: string
  depth?: string
  link?: string
  title?: string
  qtyDisp?: number
  isAvaible?: boolean
  paquete?: number
  medida?: number
}

@Injectable({
  providedIn: 'root'
})
export class ValidarInvService {

  private _BaseUrl: string = "https://test.payments.lideart.com.mx/lideart/carrito/inventario";

  private http = inject(HttpClient);

  private _isItemPaquete(item: CartData): boolean {
    try {
      return isNaN(parseInt(item.sku[0]));
    } catch (error) {
      console.error("Se requiere un SKU valido para determinar si es un paquete o no", { item });
      return false;
    }
  }

  public convertCartDataToInputProduct(cartItems: CartData[]): InputProduct[] {
    return cartItems.map(item => ({
      qty: item.quantity || 1,
      id: item.id_product || '',
      sku: item.sku || '',
      unitId: item.unitId || '',
      isPaquete: this._isItemPaquete(item),
      depth: parseFloat(item.depth || item.largo || '0'),
      height: parseFloat(item.height || item.alto || '0'),
      weight: parseFloat(item.weight || item.peso || '0'),
      width: parseFloat(item.width || item.ancho || '0')
    }));
  }

  private _sendApiVerify(data: InputData) {
    return this.http.post<OutputProduct[]>(this._BaseUrl, data);
  }

  public async verify(data: InputData) {
    return this._sendApiVerify(data).toPromise();
  }

  public async addStockInfo(data: CartData[]): Promise<CartData[]> {
    try {
      const convertedData = this.convertCartDataToInputProduct(data);
      const response = await this.verify({ products: convertedData });
      if (!response) {
        console.error("Error respuesta de verificar inventario no retorno nada", { response });
        return data;
      };

      const reponseMap = data.map((item, i) => {
        const responseItem = response.find(r => r.sku === item.sku);
        return {
          ...item,
          paquete: item.paquete || responseItem?.isPaquete ? 1 : 0 || 0,
          qtyDisp: responseItem?.qtyDisp || 0,
          isAvaible: responseItem?.isAvaible || false
        }
      })

      return reponseMap;
    }
    catch (e) {
      console.error("Error al verificar inventario", e);
      return data;
    }
  }

  public async isAllWithStock(data: InputData): Promise<boolean> {
    try {
      const response = await this.verify(data);
      return response && response.every(item => item.isAvaible) || false;
    }
    catch (e) {
      console.error("Error al verificar inventario", e);
      return false;
    }
  }
}
