import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { Usuario } from '../../Models/usuario';
import { CarritoServiceService } from '../../services/carrito-service.service';
import { CheckoutServiceService, ResumenCompra } from '../../services/checkout-service.service';
import { LoginService } from '../../services/login.service';
import { CartData } from '../../services/validar-inv.service';
import { PAY_Kueski_createPaymentResponse } from '../../types/PAYMENTS/Kueski';
import { PAY_MercadoPagoPreferenceResponse } from '../../types/PAYMENTS/MercadoPago';


@Component({
  standalone: true,
  imports: [CommonModule, RouterModule, FormsModule],
  selector: 'app-resumen-compra',
  templateUrl: './resumen-compra.component.html',
  styleUrls: ['./resumen-compra.component.scss'],
})
export class ResumenCompraComponent implements OnInit {
  METODO_ASINCRONOS: string[] = ["Kueski Pay", "Mercado Pago"]

  cargando = false;
  usuario!: Usuario;
  items: CartData[] = [];
  productos: any = []
  public grandTotal!: number;
  datosVentilla: ResumenCompra[] = [];
  correo: any;
  nombre: any;
  pais: any;
  estado: any;
  ciudad: any;
  colonia: any;
  cp: any;
  direccion: any;
  fecha: any;
  hora: any;
  metodo_pago: any;
  paqueteria: any;
  subtotal: any;
  iva: any;
  total: any;
  cotizacion: any;
  ov: any;
  productitos: any = [];
  totalisimo: any;
  etiquetas: any = [];
  modoEntrega: any;
  zonaExtendida: any;
  noCuenta = '65-50630503-1';
  clabe = '014150655063050317';
  banco = 'Santander';
  oxxo: any;
  itemsdelcarrito: any = [];
  mesa_regalos = 0;

  paymentURL: null | string = null; // Aqui se almacena la url con la que el usuario puede pagar su compra en caso que no haya sido guardada.

  datosVentillaCargado: boolean = false; // P820d

  constructor(
    private usuarioService: LoginService,
    private checkoutService: CheckoutServiceService,
    private shoppingCart: CarritoServiceService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {

  }

  private setPaymentURL({ paymentMetho, info }: { paymentMetho: string, info: any }) {
    if (paymentMetho === 'Mercado Pago' && info != null) {
      const infoParsed = JSON.parse(info) as PAY_MercadoPagoPreferenceResponse;
      this.paymentURL = infoParsed.init_point;
    }
    if (paymentMetho === 'Kueski Pay' && info != null) {
      const infoParsed = JSON.parse(info) as PAY_Kueski_createPaymentResponse;
      this.paymentURL = infoParsed.data.callback_url;
    }
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.usuarioService.usuario.subscribe(res => {
        if (res === null) {
          const sesionInvitado = localStorage.getItem('invitado');
          if (sesionInvitado) {
            this.usuario = { token: '', usuario: sesionInvitado };
          } else {
            this.router.navigate(['/login']);
            return
          }
        } else {
          this.usuario = res;
        }


      });
      this.checkoutService.getResumen(this.usuario.usuario).subscribe(datosVenta => {
        this.datosVentilla = datosVenta;

        this.setPaymentURL({ info: this.datosVentilla[0].info, paymentMetho: this.datosVentilla[0].metodo_pago });

        this.correo = this.datosVentilla[0].correo;
        this.nombre = this.datosVentilla[0].nombre;
        this.pais = this.datosVentilla[0].pais;
        this.estado = this.datosVentilla[0].estado;
        this.ciudad = this.datosVentilla[0].ciudad;
        this.colonia = this.datosVentilla[0].colonia;
        this.cp = this.datosVentilla[0].cp;
        this.direccion = this.datosVentilla[0].direccion;
        this.fecha = this.datosVentilla[0].fecha;
        this.hora = this.datosVentilla[0].hora;
        this.metodo_pago = this.datosVentilla[0].metodo_pago;
        this.paqueteria = this.datosVentilla[0].paqueteria;
        this.iva = this.datosVentilla[0].iva;
        this.subtotal = this.datosVentilla[0].subtotal;
        this.total = parseFloat(this.datosVentilla[0].total);
        this.cotizacion = this.datosVentilla[0].cotizacion;
        this.ov = this.datosVentilla[0].ov;
        this.modoEntrega = this.datosVentilla[0].tipo_entrega;
        // this.zonaExtendida = this.datosVentilla[0].zonaExtendida;
        this.etiquetas = JSON.parse(this.datosVentilla[0].arregloEtiquetas);
        this.oxxo = this.datosVentilla[0].referencia_oxxo
        this.mesa_regalos = Number(this.datosVentilla[0].mesa_regalos)
        this.itemsdelcarrito = JSON.parse(this.datosVentilla[0].arrayCarrito);



        if (this.mesa_regalos == 1) {
          this.shoppingCart.cartItems2.subscribe(data => {
            this.items = data;
            this.productos = this.items;
            if (this.items) {

              this.getTotal(this.items);
            }
            this.grandTotal = this.totalisimo;
            this.productitos = this.productos;
            this.cargando = false;
          });
        } else {
          this.shoppingCart.cartItems.subscribe(data => {
            this.items = data;
            this.productos = this.items;
            if (this.items) {

              this.getTotal(this.items);
            }
            this.grandTotal = this.totalisimo;
            this.productitos = this.productos;
            this.cargando = false;
          });
        }

        this.datosVentillaCargado = true; // P2ecf

      })
    }



    if (this.mesa_regalos == 1) {
      this.shoppingCart.cartItems3.subscribe(data => {
        this.items = data;
        this.productos = this.items;
        if (this.items) {

          this.getTotal(this.items);
        }
        this.grandTotal = this.totalisimo;
        this.productitos = this.productos;
        this.cargando = false;
      });
    } else {
      this.shoppingCart.cartItems.subscribe(data => {
        this.items = data;
        this.productos = this.items;
        if (this.items) {

          this.getTotal(this.items);
        }
        this.grandTotal = this.totalisimo;
        this.productitos = this.productos;
        this.cargando = false;
      });
    }





  }

  getTotal(data: any) {
    let subs = 0;

    for (const item of data) {
      subs += Math.round((item.precio * 1.16) * 100) / 100 * item.quantity;
      this.grandTotal = subs;

    }
  }


  // agarraResumen(){
  //   this.cargando = true;




  // }

  finalizar() {

    if (this.mesa_regalos == 1) {
      this.shoppingCart.deleteCart2();


      this.router.navigate(['/home']);

    } else {
      this.shoppingCart.deleteCart();


      this.router.navigate(['/home']);
    }

  }


}
