@if(isDesk) {
<section class="home-desk-cont" id="swiperReview">

  <section class="banner-formas-de-pago">
    <div class="part-cont f-row" style="width: 25%;">
      <i class="fa-light fa-truck-fast truckin"></i>
      <div class="txt-cont-1">
        <span class="span-up">¡ENVÍO GRATIS!</span>
        <span class="span-down">a partir de <span class="stronger">$2,000.00</span> de compra.</span>
      </div>
    </div>
    <div class="part-cont f-col">
      <span class="txt-p1-2">3 MESES SIN INTERESES</span>
      <span class="txt-p2-2">a partir de <span class="stronger">$3,000.00</span> de compra con PayPal y tarjeta de
        crédito.</span>
    </div>
    <div class="part-cont f-row">
      <img style="width: 6em; height: auto;" src="assets/img/homeNewIcons/formapago.webp" alt="">
      <div class="rin-col">
        <img class="pagos-icons" src="assets/img/homeNewIcons/tarjetas.webp" alt="">
        <span class="txt-pagos">Tarjetas de<br>crédito y débito</span>
      </div>
      <div class="rin-col">
        <img class="pagos-icons" src="assets/img/homeNewIcons/transfrencia.webp" alt="">
        <span class="txt-pagos">Transferencia</span>
      </div>
      <div class="rin-col">
        <img class="pagos-icons" src="assets/img/homeNewIcons/paypal.webp" alt="">
        <span class="txt-pagos">PayPal</span>
      </div>
      <div class="rin-col">
        <img class="pagos-icons" src="assets/img/homeNewIcons/oxxo.webp" alt="">
        <span class="txt-pagos">OXXO</span>
      </div>
      <div class="rin-col">
        <img class="pagos-icons" src="assets/img/homeNewIcons/KP-51.webp" alt="">
        <span class="txt-pagos">Kueskipay</span>
      </div>
      <div class="rin-col">
        <img class="pagos-icons" src="assets/img/homeNewIcons/MP-52.webp" alt="">
        <span class="txt-pagos">Mercado<br>Pago</span>
      </div>
    </div>
  </section>

  <div class="tampocoExisto">
    <swiper-container space-between="1" navigation="true" id="swiperDesk">
      <ng-container *ngFor="let item of slider; index as i" style="width: 100%;height:100%">
        <swiper-slide>
          <img class="imgSlider" src="{{urlIMG+'/'+item.image}}" alt="Lideart banners">
          <div class="noExisto" *ngIf="item.app_url !=''" [routerLink]="['/'+item.tipo_cat,item.app_url]"></div>
          <a class="noExisto2" target="_blank" *ngIf="item.app_url ==''" href="{{item.url}}"></a>
        </swiper-slide>
      </ng-container>
    </swiper-container>
  </div>



  <section class="special-btns-cont">
    <div class="fix">
      <!-- <div class="col-i">
        <button class="opc-s pc-1">
          <img src="assets/img/homeNewIcons/btn-1-a.webp" alt="" class="img-a">
          <img src="assets/img/homeNewIcons/btn-1-b.webp" alt="" class="img-b">
        </button>
        <span class="txt-opc">
          Cambiate a Plus y comienza a disfrutar beneficios exclusivos.
        </span>
      </div> -->
      <!-- <div class="col-i">
        <button class="opc-s pc-2">
          <img src="assets/img/homeNewIcons/btn-2-a.webp" alt="" class="img-a">
          <img src="assets/img/homeNewIcons/btn-2-b.webp" alt="" class="img-b">
        </button>
        <span class="txt-opc">
          La tarjeta de regalo perfect para esa personita creativa de tu vida.
        </span>
      </div> -->
      <!-- <div class="col-i">
        <button class="opc-s pc-3">
          <img src="assets/img/homeNewIcons/btn-3-a.webp" alt="" class="img-a">
          <img src="assets/img/homeNewIcons/btn-3-b.webp" alt="" class="img-b">
        </button>
        <span class="txt-opc">
          ¿Celebras una ocación especial? Conoce nuestra Mesa de Regalos.
        </span>
      </div> -->
      <div class="col-i" routerLink="/blog">
        <button class="opc-s pc-4">
          <img src="assets/img/homeNewIcons/btn-4-a.webp" alt="" class="img-a" style="width: 60%;">
          <img src="assets/img/homeNewIcons/btn-4-b.webp" alt="" class="img-b" style="width: 60%;">
        </button>
        <span class="txt-opc">
          Descubre nuevas técnicas y tips que te ayudarán en tus proyectos.
        </span>
      </div>
      <div class="col-i" routerLink="/promociones-cricut">
        <button class="opc-s pc-5">
          <img src="assets/img/homeNewIcons/btn-5-a.webp" alt="" class="img-a">
          <img src="assets/img/homeNewIcons/btn-5-b.webp" alt="" class="img-b">
        </button>
        <span class="txt-opc">
          ¡¡Aprovecha los descuentos en equipos y materiales!!.
        </span>
      </div>
      <div class="col-i" routerLink="/catalogo/155/lo-más-nuevo">
        <button class="opc-s pc-6">
          <img src="assets/img/homeNewIcons/btn-6-a.webp" alt="" class="img-a">
          <img src="assets/img/homeNewIcons/btn-6-b.webp" alt="" class="img-b">
        </button>
        <span class="txt-opc">
          Conoce lo nuevo que tenemos disponible para ti.
        </span>
      </div>
    </div>
  </section>




  <!-- <div class="cats-cont">
    <div class="lain">
      <a class="cat-cont" [routerLink]="['/paquetes']" routerLinkActive="router-link-active">
        <div class="cont-img">
          <img src="assets/img/cats-home/paquetes.webp" alt="Lideart paquetes">
        </div>
        <div class="rondin"></div>
      </a>
      <a class="cat-cont" [routerLink]="['/catalogo/20/plotters-de-corte']" routerLinkActive="router-link-active">
        <div class="cont-img">
          <img src="assets/img/cats-home/plotters.webp" alt="Lideart plotters">
        </div>
        <div class="rondin"></div>
      </a>
      <a class="cat-cont" [routerLink]="['/catalogo/37/laminadoras']" routerLinkActive="router-link-active">
        <div class="cont-img">
          <img src="assets/img/cats-home/laminadoras.webp" alt="Lideart laminadoras">
        </div>
        <div class="rondin"></div>
      </a>
    </div>
    <div class="lain">
      <a class="cat-cont" [routerLink]="['/catalogo/35/planchas-de-calor']" routerLinkActive="router-link-active">
        <div class="cont-img">
          <img src="assets/img/cats-home/planchas-de-calor.webp" alt="Lideart placnahs de calor">
        </div>
        <div class="rondin"></div>
      </a>
      <a class="cat-cont" [routerLink]="['/catalogo/56/troqueladoras']" routerLinkActive="router-link-active">
        <div class="cont-img">
          <img src="assets/img/cats-home/troqueladoras.webp" alt="Lideart troqueladoras">
        </div>
        <div class="rondin"></div>
      </a>
      <a class="cat-cont" [routerLink]="['/catalogo/18/vinil-y-papel-para-rotulacion']"
        routerLinkActive="router-link-active">
        <div class="cont-img">
          <img src="assets/img/cats-home/vinil-papel-rotulacion.webp" alt="Lideart vinil papel rotulación">
        </div>
        <div class="rondin"></div>
      </a>
    </div>
    <div class="lain">
      <a class="cat-cont" [routerLink]="['/catalogo/17/vinil-textil']" routerLinkActive="router-link-active">
        <div class="cont-img">
          <img src="assets/img/cats-home/1vinil-textil2.webp" alt="Lideart vinil textil">
        </div>
        <div class="rondin"></div>
      </a>
      <a class="cat-cont" [routerLink]="['/catalogo/128/scrapbook']" routerLinkActive="router-link-active">
        <div class="cont-img">
          <img src="assets/img/cats-home/1scrapbook2.webp" alt="Lideart scrapbook">
        </div>
        <div class="rondin"></div>
      </a>
      <a class="cat-cont" [routerLink]="['/catalogo/44/resina']" routerLinkActive="router-link-active">
        <div class="cont-img">
          <img src="assets/img/cats-home/1resina2.webp" alt="Lideart resina">
        </div>
        <div class="rondin"></div>
      </a>
    </div>
    <div class="lain">
      <a class="cat-cont" [routerLink]="['/catalogo/23/costura']" routerLinkActive="router-link-active">
        <div class="cont-img">
          <img src="assets/img/cats-home/1costura.webp" alt="Lideart costura">
        </div>
        <div class="rondin"></div>
      </a>
      <a class="cat-cont" [routerLink]="['/catalogo/58/engargolado']" routerLinkActive="router-link-active">
        <div class="cont-img">
          <img src="assets/img/cats-home/1engargoladora.webp" alt="Lideart engargoladora">
        </div>
        <div class="rondin"></div>
      </a>
      <a class="cat-cont" [routerLink]="['/catalogo/487/grabadora-laser']" routerLinkActive="router-link-active">
        <div class="cont-img">
          <img src="assets/img/cats-home/1laser.webp" alt="Lideart laser">
        </div>
        <div class="rondin"></div>
      </a>
    </div>
  </div> -->

  <section class="principal-opts-cont">
    <div class="fix">
      <div class="rowi">
        <a class="refi-p" [routerLink]="[ '/paquetes' ]" style="background: linear-gradient(90deg, #b9e9dc, #45d4e1);">
          <img class="img-p" src="assets/img/homeNewIcons/p-1.webp" alt="">
          <span class="txt-p">Paquetes</span>
        </a>
        <a class="refi-p" [routerLink]="[ '/catalogo','20','plotters-de-corte']"
          style="background: linear-gradient(90deg, #4cd0db, #00a6cd);">
          <img class="img-p" src="assets/img/homeNewIcons/p-2.webp" alt="">
          <span class="txt-p">Plotters de corte</span>
        </a>
        <a class="refi-p" [routerLink]="[ '/catalogo','37','laminadoras' ]"
          style="background: linear-gradient(90deg, #b9e9dc, #45d4e1);">
          <img class="img-p" src="assets/img/homeNewIcons/p-3.webp" alt="">
          <span class="txt-p">Laminadoras</span>
        </a>
      </div>
      <div class="rowi">
        <a class="refi-p" [routerLink]="[ '/catalogo','35','planchas-de-calor']"
          style="background: linear-gradient(90deg, #4cd0db, #00a6cd);">
          <img class="img-p" src="assets/img/homeNewIcons/p-13.webp" alt="">
          <span class="txt-p">Planchas de calor</span>
        </a>
        <a class="refi-p" [routerLink]="[ '/catalogo','56','troqueladoras' ]"
          style="background: linear-gradient(90deg, #b9e9dc, #45d4e1);">
          <img class="img-p" src="assets/img/homeNewIcons/p-5.webp" alt="">
          <span class="txt-p">Troqueladoras</span>
        </a>
        <a class="refi-p" [routerLink]="['/catalogo','18','vinil-y-papel-para-rotulacion']"
          style="background: linear-gradient(90deg, #4cd0db, #00a6cd);">
          <img class="img-p" src="assets/img/homeNewIcons/p-6.webp" alt="">
          <span class="txt-p">Vinil y papel de rotulación</span>
        </a>
      </div>
      <div class="rowi">
        <a class="refi-p" [routerLink]="[ '/catalogo','17','vinil-textil' ]"
          style="background: linear-gradient(90deg, #b9e9dc, #45d4e1);">
          <img class="img-p" src="assets/img/homeNewIcons/p-7.webp" alt="">
          <span class="txt-p">Vinil Textil</span>
        </a>
        <a class="refi-p" [routerLink]="[ '/catalogo','128','scrapbook' ]"
          style="background: linear-gradient(90deg, #4cd0db, #00a6cd);">
          <img class="img-p" src="assets/img/homeNewIcons/p-8.webp" alt="">
          <span class="txt-p">Scrapbook</span>
        </a>
        <a class="refi-p" [routerLink]="[ '/catalogo','44','resina' ]"
          style="background: linear-gradient(90deg, #b9e9dc, #45d4e1);">
          <img class="img-p" src="assets/img/homeNewIcons/p-9.webp" alt="">
          <span class="txt-p">Resina</span>
        </a>
      </div>
      <div class="rowi">
        <a class="refi-p" [routerLink]="[ '/catalogo/23/costura' ]"
          style="background: linear-gradient(90deg, #4cd0db, #00a6cd);">
          <img class="img-p" src="assets/img/homeNewIcons/p-10.webp" alt="">
          <span class="txt-p">máquinas de coser</span>
        </a>
        <a class="refi-p" [routerLink]="[ '/catalogo/58/engargolado' ]"
          style="background: linear-gradient(90deg, #b9e9dc, #45d4e1);">
          <img class="img-p" src="assets/img/homeNewIcons/p-11.webp" alt="">
          <span class="txt-p">Engargoladoras</span>
        </a>
        <a class="refi-p" [routerLink]="[ '/catalogo/487/grabadora-laser' ]"
          style="background: linear-gradient(90deg, #4cd0db, #00a6cd);">
          <img class="img-p" src="assets/img/homeNewIcons/p-12.webp" alt="">
          <span class="txt-p">Grabado láser</span>
        </a>
      </div>
    </div>
  </section>

  <!-- <div class="imgs-cont-opcs">
    <a class="upi" [routerLink]="['/blog']" routerLinkActive="router-link-active">
      <img src="assets/img/1inspiracion.webp" alt="Lideart inspiración">
    </a>
    <div class="dowi">
      <a [routerLink]="['/blog']" class="imag"><img src="assets/img/1experiencias.webp" alt=""></a>
      <a [routerLink]="['/catalogo/482/san-valentin-2022']" class="imag"><img src="assets/img/1promos.webp" alt=""></a>
    </div>
  </div> -->


  <!-- 
  <div class="marcas-cont">
    <h1 class="title-marca">
      MARCAS
      <span class="lain"></span>
    </h1>
    <swiper-container space-between="10" id="swiperMarcas"
      breakpoints="{&quot;992&quot;:{&quot;slidesPerView&quot;:6}}">
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/3.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/4.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/5.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/6.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/7.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/8.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/9.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/10.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/11.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/12.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/13.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/15.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/16.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/19.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/20.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/21.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/22.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/23.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/24.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/26.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/29.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/30.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/31.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/33.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/35.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/36.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/37.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/39.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/40.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/41.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/44.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/45.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/47.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/49.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/50.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/51.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/52.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/54.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/55.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/56.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/57.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/60.jpg" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide>
        <img class="imgSlider" src="assets/img/marcas/61.jpg" alt="Lideart marcas">
      </swiper-slide>
    </swiper-container>
  </div> -->





  <div class="marcas-cont">
    <h1 class="title-marca">
      En nuestra página encontrarás marcas como:
      <span class="lain"></span>
    </h1>
    <swiper-container space-between="10" id="swiperMarcas"
      breakpoints="{&quot;992&quot;:{&quot;slidesPerView&quot;:6}}">
      <swiper-slide style="background-color: #86d2e4; border-radius: 1em;">
        <img class="imgSlider" src="assets/img/Marcas2/1.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide style="background-color: #85e1e8; border-radius: 1em;">
        <img class="imgSlider" src="assets/img/Marcas2/2.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide style="background-color: #a1e9e4; border-radius: 1em;">
        <img class="imgSlider" src="assets/img/Marcas2/3.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide style="background-color: #ffdadc; border-radius: 1em;">
        <img class="imgSlider" src="assets/img/Marcas2/4.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide style="background-color: #ffdfec; border-radius: 1em;">
        <img class="imgSlider" src="assets/img/Marcas2/5.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide style="background-color: #ffdadc; border-radius: 1em;">
        <img class="imgSlider" src="assets/img/Marcas2/6.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide style="background-color: #86d2e4; border-radius: 1em;">
        <img class="imgSlider" src="assets/img/Marcas2/7.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide style="background-color: #85e1e8; border-radius: 1em;">
        <img class="imgSlider" src="assets/img/Marcas2/8.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide style="background-color: #a1e9e4; border-radius: 1em;">
        <img class="imgSlider" src="assets/img/Marcas2/9.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide style="background-color: #ffdadc; border-radius: 1em;">
        <img class="imgSlider" src="assets/img/Marcas2/10.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide style="background-color: #ffdfec; border-radius: 1em;">
        <img class="imgSlider" src="assets/img/Marcas2/11.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide style="background-color: #86d2e4; border-radius: 1em;">
        <img class="imgSlider" src="assets/img/Marcas2/12.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide style="background-color: #86d2e4; border-radius: 1em;">
        <img class="imgSlider" src="assets/img/Marcas2/13.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide style="background-color: #85e1e8; border-radius: 1em;">
        <img class="imgSlider" src="assets/img/Marcas2/14.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide style="background-color: #a1e9e4; border-radius: 1em;">
        <img class="imgSlider" src="assets/img/Marcas2/15.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide style="background-color: #ffdadc; border-radius: 1em;">
        <img class="imgSlider" src="assets/img/Marcas2/16.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide style="background-color: #ffdfec; border-radius: 1em;">
        <img class="imgSlider" src="assets/img/Marcas2/17.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide style="background-color: #86d2e4; border-radius: 1em;">
        <img class="imgSlider" src="assets/img/Marcas2/18.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide style="background-color: #86d2e4; border-radius: 1em;">
        <img class="imgSlider" src="assets/img/Marcas2/19.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide style="background-color: #85e1e8; border-radius: 1em;">
        <img class="imgSlider" src="assets/img/Marcas2/20.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide style="background-color: #a1e9e4; border-radius: 1em;">
        <img class="imgSlider" src="assets/img/Marcas2/21.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide style="background-color: #ffdadc; border-radius: 1em;">
        <img class="imgSlider" src="assets/img/Marcas2/22.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide style="background-color: #ffdfec; border-radius: 1em;">
        <img class="imgSlider" src="assets/img/Marcas2/23.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide style="background-color: #86d2e4; border-radius: 1em;">
        <img class="imgSlider" src="assets/img/Marcas2/24.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide style="background-color: #86d2e4; border-radius: 1em;">
        <img class="imgSlider" src="assets/img/Marcas2/25.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide style="background-color: #85e1e8; border-radius: 1em;">
        <img class="imgSlider" src="assets/img/Marcas2/26.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide style="background-color: #a1e9e4; border-radius: 1em;">
        <img class="imgSlider" src="assets/img/Marcas2/27.webp" alt="Lideart marcas">
      </swiper-slide>
      <swiper-slide style="background-color: #ffdadc; border-radius: 1em;">
        <img class="imgSlider" src="assets/img/Marcas2/28.webp" alt="Lideart marcas">
      </swiper-slide>
      <!-- <swiper-slide>
      <img class="imgSlider" src="assets/img/Marcas2/29.webp" alt="Lideart marcas">
    </swiper-slide>
    <swiper-slide>
      <img class="imgSlider" src="assets/img/Marcas2/30.webp" alt="Lideart marcas">
    </swiper-slide>
    <swiper-slide>
      <img class="imgSlider" src="assets/img/Marcas2/31.webp" alt="Lideart marcas">
    </swiper-slide> -->
      <!-- <swiper-slide>
      <img class="imgSlider" src="assets/img/marcas/33.webp" alt="Lideart marcas">
    </swiper-slide>
    <swiper-slide>
      <img class="imgSlider" src="assets/img/marcas/35.webp" alt="Lideart marcas">
    </swiper-slide>
    <swiper-slide>
      <img class="imgSlider" src="assets/img/marcas/36.webp" alt="Lideart marcas">
    </swiper-slide>
    <swiper-slide>
      <img class="imgSlider" src="assets/img/marcas/37.webp" alt="Lideart marcas">
    </swiper-slide>
    <swiper-slide>
      <img class="imgSlider" src="assets/img/marcas/39.webp" alt="Lideart marcas">
    </swiper-slide>
    <swiper-slide>
      <img class="imgSlider" src="assets/img/marcas/40.webp" alt="Lideart marcas">
    </swiper-slide>
    <swiper-slide>
      <img class="imgSlider" src="assets/img/marcas/41.webp" alt="Lideart marcas">
    </swiper-slide>
    <swiper-slide>
      <img class="imgSlider" src="assets/img/marcas/44.webp" alt="Lideart marcas">
    </swiper-slide>
    <swiper-slide>
      <img class="imgSlider" src="assets/img/marcas/45.webp" alt="Lideart marcas">
    </swiper-slide>
    <swiper-slide>
      <img class="imgSlider" src="assets/img/marcas/47.webp" alt="Lideart marcas">
    </swiper-slide>
    <swiper-slide>
      <img class="imgSlider" src="assets/img/marcas/49.webp" alt="Lideart marcas">
    </swiper-slide>
    <swiper-slide>
      <img class="imgSlider" src="assets/img/marcas/50.webp" alt="Lideart marcas">
    </swiper-slide>
    <swiper-slide>
      <img class="imgSlider" src="assets/img/marcas/51.webp" alt="Lideart marcas">
    </swiper-slide>
    <swiper-slide>
      <img class="imgSlider" src="assets/img/marcas/52.webp" alt="Lideart marcas">
    </swiper-slide>
    <swiper-slide>
      <img class="imgSlider" src="assets/img/marcas/54.webp" alt="Lideart marcas">
    </swiper-slide>
    <swiper-slide>
      <img class="imgSlider" src="assets/img/marcas/55.webp" alt="Lideart marcas">
    </swiper-slide>
    <swiper-slide>
      <img class="imgSlider" src="assets/img/marcas/56.webp" alt="Lideart marcas">
    </swiper-slide>
    <swiper-slide>
      <img class="imgSlider" src="assets/img/marcas/57.webp" alt="Lideart marcas">
    </swiper-slide>
    <swiper-slide>
      <img class="imgSlider" src="assets/img/marcas/60.jpg" alt="Lideart marcas">
    </swiper-slide>
    <swiper-slide>
      <img class="imgSlider" src="assets/img/marcas/61.jpg" alt="Lideart marcas">
    </swiper-slide> -->
    </swiper-container>
  </div>


  <div class="reviews-cont">
    <div class="taitl-cont">
      ÚLTIMAS OPINIONES
    </div>
    <a (click)='navigation()' style="cursor: pointer;" class="more">Ver Más >></a>
    <swiper-container space-between="10" id="swiperReview"
      breakpoints="{&quot;992&quot;:{&quot;slidesPerView&quot;:3},&quot;1200&quot;:{&quot;slidesPerView&quot;:4},&quot;1500&quot;:{&quot;slidesPerView&quot;:5}}">
      <swiper-slide *ngFor="let producto of reviews2">
        <div class="card-coment">
          <div class="taitl-cont2">
            <span class="titl">{{producto.title}}</span>
          </div>
          <div class="stars-cont">
            <img class="votes"
              src="{{'https://new.lideart.com.mx/modules/lgcomments/views/img/stars/plain/bluelight/'+producto.stars+'stars.png'}}">
          </div>
          <div class="coment-cont">
            <span class="coment">{{producto.comment}}</span>
          </div>
          <div class="who-cont">
            <span class="who">- {{producto.nick}}</span>
          </div>
          <span class="date">{{producto.date}}</span>
        </div>
      </swiper-slide>
    </swiper-container>
  </div>

  <div class="parallax-cont">
    <p class="txtin">En nuestra tienda encontrarás un amplio catálogo de consumibles y equipos para crear manualidades
      únicas. ¡Visítanos en todas nuestras redes sociales!</p>
  </div>
</section>
}




















@else if(isCel) {
<div class="cel-home quita-ionic-content">
  <div>
    <img src="/assets/img/FormasCel.webp" alt="">
  </div>
  <ion-row>
    <ion-col size="12">
      <div class="tampocoExisto">
        <div class="cardContainer">
          <swiper-container space-between="1" pagination="true" modules={[Pagination]} class="mySwiper"
            id="swiperCelBanns">
            <ng-container *ngFor="let item of slider; index as i" style="width: 100%;height:100%">
              <swiper-slide>
                <div *ngIf="isLoading" class="loader"></div>
                <img class="imgSlider" (load)="onImageLoad()" [ngSrc]="urlIMG+'/'+item.image" alt="Lideart banner"
                  [priority]="i === 0" fill>
                <div class="noExisto" *ngIf="item.app_url !=''" [routerLink]="['/'+item.tipo_cat,item.app_url]"></div>
                <a class="noExisto2" target="_blank" *ngIf="item.app_url ==''" href="{{item.url}}"></a>
              </swiper-slide>
            </ng-container>
          </swiper-container>
        </div>
      </div>
    </ion-col>
  </ion-row>

  <ion-grid>
    <ion-row>
      <ion-col size="4" class="custom-col-header">
        <div routerLink="/blog"
          style="height: 80px;border:solid 2px #f7cad3; border-radius: 10px; padding: 5px 5px 5px 5px; display: flex; justify-content: center; align-items: center;">

          <img src="assets/img/homeNewIcons/btn-4-a.webp" width="80%" alt="">

        </div>
        <div style="display: flex; justify-content: center; align-items: center;text-align: center;">
          <span style="font-size: 10px;">
            Descubre nuevas técnicas y tips que te ayudarán en tus proyectos.
          </span>
        </div>

      </ion-col>
      <ion-col size="4" class="custom-col-header">
        <div
          style="height: 80px;border: solid 2px #f7cad3; border-radius: 10px; padding: 5px 5px 5px 5px; display: flex; justify-content: center; align-items: center;">
          <img src="assets/img/homeNewIcons/btn-5-a.webp" alt="">

        </div>
        <div style="display: flex; justify-content: center; align-items: center;text-align: center;">
          <span style="font-size: 10px;">
            ¡¡Aprovecha los descuentos en equipos y materiales!!.
          </span>
        </div>

      </ion-col>



      <ion-col size="4" class="custom-col-header">
        <div
          style="height: 80px;border: solid 2px #f7cad3; border-radius: 10px; padding: 5px 5px 5px 5px; display: flex; justify-content: center; align-items: center;"
          routerLink="/catalogo/155/lo-más-nuevo">

          <img src="assets/img/homeNewIcons/btn-6-a.webp" alt="">

        </div>
        <div style="display: flex; justify-content: center; align-items: center;text-align: center;">
          <span style="font-size: 10px;">
            Conoce lo nuevo que tenemos disponible para ti.
          </span>

        </div>

      </ion-col>
    </ion-row>

  </ion-grid>

  <div>
    <img src="/assets/img/BannerAPP.webp" alt="">
  </div>
  <br>
  @if (!this.APP_CONTEXT.isApp) {
  <div style="display: flex; align-items: center; justify-content: center; text-align: center;">
    <span style="font-weight: bold;font-size: 20px;color:#7f7f7f">Disponible en:</span>
  </div>

  <ion-grid>
    <ion-row>
      <ion-col>
        <div style="display: flex; align-items: center;justify-content: center;text-align:center;">
          <a href="https://play.google.com/store/apps/details?id=io.ionic.lideart&pcampaignid=web_share" target="_blank"
            style="display: flex; align-items: center;justify-content: center;text-align:center;">
            <img src="/assets/img/AppAndroid.webp" height="80%" width="80%" alt=""></a>
        </div>
      </ion-col>
      <ion-col>
        <div style="display: flex; align-items: center;justify-content: center;text-align:center;">
          <a href="https://apps.apple.com/mx/app/lideart/id6475698477" target="_blank"
            style="display: flex; align-items: center;justify-content: center;text-align:center;"><img
              src="/assets/img/AppIos.webp" height="80%" width="80%" alt=""></a>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
  }



  <!-- <section class="wrap-cats-princ">
    <button [routerLink]="['/catalogo','20','plotters-de-corte']" class="btn-wrap">
      <img ngSrc="assets/img/homeNewIcons/p-2.webp" alt="Lideart plotters de corte" fill />
    </button>
    <button [routerLink]="['/catalogo','37','laminadoras']" class="btn-wrap">
      <img ngSrc="assets/img/homeNewIcons/p-3.webp" alt="Lideart laminadoras" fill />
    </button>
    <button [routerLink]="['/catalogo','35','planchas-de-calor']" class="btn-wrap">
      <img ngSrc="assets/img/homeNewIcons/p-4.webp" alt="Lideart planchas de calor" fill />
    </button>
    <button [routerLink]="['/catalogo','56','troqueladoras']" class="btn-wrap">
      <img ngSrc="assets/img/homeNewIcons/p-5.webp" alt="Lideart troqueladoras" fill />
    </button>
    <button [routerLink]="['/catalogo','18','vinil-y-papel-para-rotulacion']" class="btn-wrap">
      <img ngSrc="assets/img/homeNewIcons/p-6.webp" alt="Lideart vinil y papel de rotulación" fill />
    </button>
    <button [routerLink]="['/catalogo','17','vinil-textil']" class="btn-wrap">
      <img ngSrc="assets/img/homeNewIcons/p-7.webp" alt="Lideart vini textil" fill />
    </button>
    <button [routerLink]="['/catalogo','128','scrapbook']" class="btn-wrap">
      <img ngSrc="assets/img/homeNewIcons/p-8.webp" alt="Lideart scrapbook" fill />
    </button>
    <button [routerLink]="['/catalogo','44','resina']" class="btn-wrap">
      <img ngSrc="assets/img/homeNewIcons/p-10.webp" alt="Lideart resina" fill />
    </button>
  </section> -->


  <ion-grid>

    <!-- ROW 1 -->


    <ion-row>
      <ion-col size="6">
        <div style="border: solid 2px #00a8ce;border-radius: 10px 10px 0px 0px;"
          [routerLink]="['/catalogo/20/plotters-de-corte']">
          <div>
            <img src="assets/img/homeNewIcons/p-2.webp" height="80%" width="100%" alt="">
          </div>

          <div style="background-color: #00a8ce;display: flex; justify-content: center; text-align: center;">
            <span style="color: white;"> Plotters de corte</span>
          </div>

        </div>
      </ion-col>
      <ion-col size="6">
        <div style="border: solid 2px #4ad3df;border-radius: 10px 10px 0px 0px;"
          [routerLink]="['/catalogo/37/laminadoras']">
          <div>
            <img src="assets/img/homeNewIcons/p-3.webp" height="80%" width="100%" alt="">
          </div>

          <div style="background-color: #4ad3df;display: flex; justify-content: center; text-align: center;">
            <span style="color: white;"> Laminadoras</span>
          </div>

        </div>
      </ion-col>
    </ion-row>


    <!-- ROW 2 -->


    <ion-row>
      <ion-col size="6">
        <div style="border: solid 2px #ffb097;border-radius: 10px 10px 0px 0px;"
          [routerLink]="['/catalogo/35/planchas-de-calor']">
          <div>
            <img src="assets/img/homeNewIcons/p-13.webp" height="80%" width="100%" alt="">
          </div>

          <div style="background-color: #ffb097;display: flex; justify-content: center; text-align: center;">
            <span style="color: white;"> Planchas de calor</span>
          </div>

        </div>
      </ion-col>
      <ion-col size="6">
        <div style="border: solid 2px #ffb6bb;border-radius: 10px 10px 0px 0px;"
          [routerLink]="['/catalogo/56/troqueladoras']">
          <div>
            <img src="assets/img/homeNewIcons/p-5.webp" height="80%" width="100%" alt="">
          </div>

          <div style="background-color: #ffb6bb;display: flex; justify-content: center; text-align: center;">
            <span style="color: white;"> Troqueladoras</span>
          </div>

        </div>
      </ion-col>
    </ion-row>


    <!-- ROW 3 -->


    <ion-row>
      <ion-col size="6">
        <div style="border: solid 2px #bca2cc;border-radius: 10px 10px 0px 0px;"
          [routerLink]="['/catalogo/18/vinil-y-papel-para-rotulacion']">
          <div>
            <img src="assets/img/homeNewIcons/p-6.webp" height="80%" width="100%" alt="">
          </div>

          <div style="background-color: #bca2cc;display: flex; justify-content: center; text-align: center;">
            <span style="color: white;"> Vinil y papel de rotulación</span>
          </div>

        </div>
      </ion-col>
      <ion-col size="6">
        <div style="border: solid 2px #71c8bf;border-radius: 10px 10px 0px 0px;"
          [routerLink]="['/catalogo/17/vinil-textil']">
          <div>
            <img src="assets/img/homeNewIcons/p-7.webp" height="80%" width="100%" alt="">
          </div>

          <div style="background-color: #71c8bf;display: flex; justify-content: center; text-align: center;">
            <span style="color: white;"> Vinil textil</span>
          </div>

        </div>
      </ion-col>
    </ion-row>

    <!-- ROW 4 -->

    <ion-row>
      <ion-col size="6">
        <div style="border: solid 2px #00a8ce;border-radius: 10px 10px 0px 0px;"
          [routerLink]="['/catalogo/128/scrapbook']">
          <div>
            <img src="assets/img/homeNewIcons/p-8.webp" height="80%" width="100%" alt="">
          </div>

          <div style="background-color: #00a8ce;display: flex; justify-content: center; text-align: center;">
            <span style="color: white;">Scrapbook</span>
          </div>

        </div>
      </ion-col>
      <ion-col size="6">
        <div style="border: solid 2px #4ad3df;border-radius: 10px 10px 0px 0px;" [routerLink]="['/catalogo/44/resina']">
          <div>
            <img src="assets/img/homeNewIcons/p-9.webp" height="80%" width="100%" alt="">
          </div>

          <div style="background-color: #4ad3df;display: flex; justify-content: center; text-align: center;">
            <span style="color: white;"> Resina</span>
          </div>

        </div>
      </ion-col>
    </ion-row>

    <!-- ROW 5 -->

    <ion-row>
      <ion-col size="6">
        <div style="border: solid 2px #ffb097;border-radius: 10px 10px 0px 0px;"
          [routerLink]="['/catalogo/23/costura']">
          <div>
            <img src="assets/img/homeNewIcons/p-10.webp" height="80%" width="100%" alt="">
          </div>

          <div style="background-color: #ffb097;display: flex; justify-content: center; text-align: center;">
            <span style="color: white;">Máquinas de coser</span>
          </div>

        </div>
      </ion-col>
      <ion-col size="6">
        <div style="border: solid 2px #ffb6bb;border-radius: 10px 10px 0px 0px;"
          [routerLink]="['/catalogo/58/engargolado']">
          <div>
            <img src="assets/img/homeNewIcons/p-11.webp" height="80%" width="100%" alt="">
          </div>

          <div style="background-color: #ffb6bb;display: flex; justify-content: center; text-align: center;">
            <span style="color: white;">Engargoladoras</span>
          </div>

        </div>
      </ion-col>
    </ion-row>

    <!-- ROW 6 -->

    <ion-row>
      <ion-col size="6">
        <div style="border: solid 2px #bca2cc;border-radius: 10px 10px 0px 0px;"
          [routerLink]="[ '/catalogo/487/grabadora-laser' ]">
          <div>
            <img src="assets/img/homeNewIcons/p-12.webp" height="80%" width="100%" alt="">
          </div>

          <div style="background-color: #bca2cc;display: flex; justify-content: center; text-align: center;">
            <span style="color: white;">Grabado Laser</span>
          </div>

        </div>
      </ion-col>
      <ion-col size="6">
        <div style="border: solid 2px #71c8bf;border-radius: 10px 10px 0px 0px;" [routerLink]="[ '/paquetes' ]">
          <div>
            <img src="assets/img/homeNewIcons/p-1.webp" height="80%" width="100%" alt="">
          </div>

          <div style="background-color: #71c8bf;display: flex; justify-content: center; text-align: center;">
            <span style="color: white;">Paquetes</span>
          </div>

        </div>
      </ion-col>
    </ion-row>

  </ion-grid>


</div>
<!-- <ion-grid>
  <ion-row>
    <ion-col size="12" class="custom-col-header">
      <div class="cardContainer">
        <button block routerLink="/paquetes" style="width: 100%; height: 12em;">
          <img ngSrc="assets/img/homeNewIcons/p-1.webp" alt="Lideart pquetes" fill />
 
        </button>
      </div>
    </ion-col>
  </ion-row>
</ion-grid> -->
}