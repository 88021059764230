import { CommonModule, DatePipe, isPlatformBrowser, Location } from '@angular/common';
import { ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, Inject, PLATFORM_ID, Renderer2, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NavigationStart, Router, RouterModule, RouterOutlet } from '@angular/router';

/* Librerías */
import { AlertController, IonicModule, MenuController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';

/* Componentes */
import { DeskFooterComponent } from './components/desk-footer/desk-footer.component';
import { DeskHeaderComponent } from './components/desk-header/desk-header.component';

/* Modelos */
import { Usuario } from './Models/usuario';

/* Servicios */
import { FormsModule } from '@angular/forms';
import Fuse from 'fuse.js';
import { ApiBuscadorGoogleService, DEFAULT_RESPONSE_GOOGLE } from './services/api-buscador-google.service';
import { ApiProductosService } from './services/api-productos.service';
import { AuthenticationService } from './services/authentication.service';
import { CarritoServiceService } from './services/carrito-service.service';
import { BuscadorService } from './services/handle-buscador.service';
import { HandleInpSearchService } from './services/handle-inp-search.service';
import { LoginService } from './services/login.service';
import { DataService } from './services/test.service';
import { TiktokEventsService } from './services/tiktok-events.service';
import { searchResult } from './types/googleSearch';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    RouterModule,
    CommonModule,
    IonicModule,
    DeskHeaderComponent,
    DeskFooterComponent,
    FormsModule,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [
    CarritoServiceService,
    LoginService,
    AuthenticationService,
    ApiProductosService,
    DataService,
    DatePipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  public isDarkTheme: boolean = false;
  usuario!: Usuario;
  nombre!: string;
  apellido!: string;
  iosOrAndroid!: boolean;
  datosUsuario: any = [];
  versionAppstore: any;
  fechita = new Date();
  nuevaFecha: any;
  private activeInterval: any;


  productosFlash = [
    {
      nombre: 'Producto 1',
      descripcion: 'Descripción del producto 1',
      precio: 99.99,
      imagenes: [
        'assets/img/oferta-producto1-1.jpg',
        'assets/img/oferta-producto1-2.jpg',
        'assets/img/oferta-producto1-3.jpg'
      ]
    },
    {
      nombre: 'Producto 2',
      descripcion: 'Descripción del producto 2',
      precio: 149.99,
      imagenes: [
        'assets/img/oferta-producto2-1.jpg',
        'assets/img/oferta-producto2-2.jpg'
      ]
    }
  ];

  showPopup = true;

  public isDesk: boolean = false;
  public isCel: boolean = false;
  public searchbarVisible: boolean = false;
  public totalItems: number = 0;
  public resultadoBusqueda: any = null;
  public txtBuscador: string = '';
  public numeroResultados: number = 0;
  public productos: any = [];
  cargando = false;
  sus!: Subscription;
  version = 18;
  respaq: any;
  resultadosBusqueda: any = []
  showAllColors: boolean = false;
  coloresMostrados: string[] = [];
  public options = {
    // isCaseSensitive: false,
    // includeScore: false,
    shouldSort: true,
    // includeMatches: false,
    // findAllMatches: false,
    minMatchCharLength: 5,
    // location: 0,
    threshold: 0.5,
    distance: 60,
    // useExtendedSearch: false,
    // ignoreLocation: false,
    // ignoreFieldNorm: false,
    // fieldNormWeight: 1,
    keys: ['nombre', 'refi', 'tags'],
  };

  @ViewChild('pistasCont') pistasCont!: ElementRef<HTMLInputElement>;
  @ViewChild('ud1') ud1!: ElementRef<HTMLInputElement>;
  pistasContIs = false;

  @ViewChild('cardMarcas') cardMarcas!: ElementRef<HTMLInputElement>;
  @ViewChild('ud2') ud2!: ElementRef<HTMLInputElement>;
  cardMarcasIs = false;

  urlsafe!: SafeResourceUrl;
  chat = "https://chat.chatra.io/?isModern=true#hostId=WsCwNXHL8qFQnNjAK&mode=widget&clientId=4qr0d6m_fGVCXNJKniJ97bGayZtpkM3v-GI73rjE&lang=es&currentPage=https%3A%2F%2Flideart.com.mx%2F&currentPageTitle=Lideart&prevPage=&referrer="

  /*get gifs(){
    return this.dataService.historial;
  }*/

  @ViewChild('hedin') hedin!: ElementRef<HTMLInputElement>;
  dataHeader: any;

  @ViewChild('searchInput') searchInput!: ElementRef;

  @ViewChild('focus', { read: ElementRef }) tableInput!: ElementRef;

  @ViewChild('celMen') celMen!: ElementRef<HTMLInputElement>;
  celMenIs = false;

  @ViewChild('ulElement') ulElement!: ElementRef;
  private observer!: MutationObserver;
  public banderaLi: boolean = false;

  searchQuery!: string;

  public totalLoaded: boolean = false;

  startIndex: number = 1;
  sortOrder: string = '';
  results: searchResult = DEFAULT_RESPONSE_GOOGLE;
  query: string = '';

  constructor(
    private menu: MenuController,
    private shoppingCart: CarritoServiceService,
    private renderer: Renderer2,
    public usuarioService: LoginService,
    private authService: AuthenticationService,
    private router: Router,
    private apiProductos: ApiProductosService,
    public alertController: AlertController,
    private location: Location,
    private platform: Platform,
    public sanitizer: DomSanitizer,
    private dataService: DataService,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe,
    private txtBuscadorService: HandleInpSearchService,
    private handleLoad: BuscadorService,
    public customSearchService: ApiBuscadorGoogleService,
    private tiktokEvents: TiktokEventsService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    /*afterRender(()=>{
      
    })*/

    // const info: DeviceInfo = await Device.getInfo();
    // this.iosOrAndroid = (info.platform === "android" || info.platform === "ios");
    // this.sendSms() 

    this.urlsafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.chat);
  }

  ngOnInit() {
    // 
    // this.apiProductos.getProductos().subscribe((response) => {
    //   this.productos = response;

    // });
    this.txtBuscadorService.txtBuscador$.subscribe((txtb) => {
      this.searchQuery = txtb;
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // Check if the URL contains the CSE fragment and replace it
        if (event.url.includes('#gsc.tab')) {
          this.router.navigateByUrl('/google-search/' + this.searchQuery);
        }

        // *Aqui se maneja el evento ViewContent (exepto en productos)
        if (!event.url.endsWith('html')) { //* Esto se hace para comprobar si se esta visualizando un producto; el evento de 
          this.tiktokEvents.sendEvent({ event: 'ViewContent' })
        }

        if (isPlatformBrowser(this.platformId)) {
          if (event instanceof NavigationStart) {
            setTimeout(() => {
              window.scrollTo(0, 0);
            }, 0);
          }
        }
      }
    });

    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth > 992) {
        this.isDesk = true;
      } else if (window.innerWidth <= 992) {
        this.isCel = true;
      }

      this.usuarioService.usuario.subscribe(res => {
        if (res === null) return
        this.usuario = res;
        if (this.usuario) {
          this.checalo();
        }
      });

      this.shoppingCart.cartItems.subscribe(d => {
        if (d && Array.isArray(d)) {
          this.totalItems = d.length;
        } else {
          this.totalItems = 0; // O cualquier valor por defecto que tenga sentido en tu contexto
        }
        this.cdr.detectChanges();
      });

      window.onload = () => {
        this.handleLoad.setLoad(true);
      };

      this.checalo();
      // this.checaVersion(); 
    }

    this.handleLoad.handleLoad$.subscribe((state: any) => {
      this.totalLoaded = state;
    });

    this.sus = this.platform.backButton.subscribe(() => {
      this.location.back();
    });

    this.nuevaFecha = this.datePipe.transform(this.fechita, "MM-dd-yyyy");
  }

  scrollUp(): void {
    this.tableInput.nativeElement.scrollTop = 0;
  }

  ngAfterViewInit() {
    this.initMutationObserver();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['txtBuscador']) {
      this.checkForListItems();
    }
  }

  onSearchChange(query: any) {
    this.query = query;

    if (!this.query || this.query.trim() === '') {
      // Limpia los resultados con un objeto vacío que cumpla con el tipo searchResult
      this.results = {
        kind: '',
        url: { type: '', template: '' },
        queries: { request: [], nextPage: [], previousPage: [] },
        context: { title: '' },
        searchInformation: { searchTime: 0, formattedSearchTime: '', totalResults: '', formattedTotalResults: '' },
        items: [] // Lista vacía de resultados
      };
      return;
    }

    this.customSearchService.search(this.query, this.startIndex, this.sortOrder).subscribe(response => {
      this.results = response;

      this.results.items = this.results.items?.map((item: any) => ({
        ...item,
        link: this.extractPath(item.link)
      }));
    });
    /*if (isPlatformBrowser(this.platformId)) {
      if (!this.txtBuscador) {
        this.banderaLi = false;
      } else if (this.ulElement) {
        this.initMutationObserver();
      }
    }*/
  }

  nextPage(): void {
    if (!this.results.queries.nextPage) return
    const startIndex = this.results.queries.nextPage[0].startIndex;
    this.scrollUp();
    this.startIndex += 10;
    this.customSearchService.search(this.query, startIndex, this.sortOrder).subscribe(response => {
      this.results = response;

      this.results.items = this.results.items?.map((item: any) => ({
        ...item,
        link: this.extractPath(item.link)
      }));
    });
  }

  prevPage(): void {
    if (!this.results.queries.previousPage) return
    const startIndex = this.results.queries.previousPage[0].startIndex;
    this.scrollUp();
    this.startIndex -= 10;
    this.customSearchService.search(this.query, startIndex, this.sortOrder).subscribe(response => {
      this.results = response;

      this.results.items = this.results.items?.map((item: any) => ({
        ...item,
        link: this.extractPath(item.link)
      }));
    });
  }

  private extractPath(url: string): string {
    try {
      const parsedUrl = new URL(url);
      return `/${parsedUrl.pathname}`;
    } catch (error) {
      return url;
    }
  }

  cleanBusk() {
    this.txtBuscador = '';
  }

  onEnterCel(searchQuery: string) {
    this.txtBuscador = '';
    const currentUrl = this.router.url;
    sessionStorage.setItem('initialUrl', currentUrl);
    this.txtBuscadorService.setTxtBuscador(searchQuery);
    this.router.navigate(['/google-search/' + searchQuery]);
    //this.router.navigate(['/google-search'], { queryParams: { query: searchQuery } });
  }

  datasas() {
    //console.log(this.dataHeader);
    if (this.dataHeader == 1) {
      this.renderer.setStyle(this.hedin.nativeElement, 'display', 'none');
    } else if (this.dataHeader == 2) {
      this.renderer.setStyle(this.hedin.nativeElement, 'display', 'block');
    }
  }

  handlePistas() {
    if (this.pistasContIs == false) {
      this.renderer.setStyle(this.pistasCont.nativeElement, 'height', '0em');
      this.renderer.setStyle(this.pistasCont.nativeElement, 'padding', '0em');
      this.renderer.removeClass(this.ud1.nativeElement, 'fa-chevron-up');
      this.renderer.addClass(this.ud1.nativeElement, 'fa-chevron-down');

      this.pistasContIs = true;
    } else if (this.pistasContIs == true) {
      this.renderer.setStyle(this.pistasCont.nativeElement, 'height', '8em');
      this.renderer.setStyle(this.pistasCont.nativeElement, 'padding', '1em');
      this.renderer.removeClass(this.ud1.nativeElement, 'fa-chevron-down');
      this.renderer.addClass(this.ud1.nativeElement, 'fa-chevron-up');

      this.pistasContIs = false;
    }
  }

  handleCard1() {
    if (this.cardMarcasIs == false) {
      this.renderer.setStyle(this.cardMarcas.nativeElement, 'height', '12em');
      this.renderer.setStyle(this.cardMarcas.nativeElement, 'padding', '.1em');
      this.renderer.removeClass(this.ud2.nativeElement, 'fa-chevron-up');
      this.renderer.addClass(this.ud2.nativeElement, 'fa-chevron-down');

      this.cardMarcasIs = true;
    } else if (this.cardMarcasIs == true) {
      this.renderer.setStyle(this.cardMarcas.nativeElement, 'height', '3em');
      this.renderer.setStyle(this.cardMarcas.nativeElement, 'padding', '0em');
      this.renderer.removeClass(this.ud2.nativeElement, 'fa-chevron-down');
      this.renderer.addClass(this.ud2.nativeElement, 'fa-chevron-up');

      this.cardMarcasIs = false;
    }
  }

  togmenu() {
    if (this.celMenIs == false) {
      this.renderer.setStyle(this.celMen.nativeElement, 'left', '0');

      this.celMenIs = true;
    } else if (this.celMenIs == true) {
      this.renderer.setStyle(this.celMen.nativeElement, 'left', '-100%');

      this.celMenIs = false;
    }
  }

  mevoy() {
    this.resultadosBusqueda = JSON.parse(localStorage.getItem('productos_buscados') || '[]');
    this.cargando = true;

    // Assign interval to class property
    this.activeInterval = setInterval(() => {
      const productosBuscados = localStorage.getItem('productos_buscados');

      if (!productosBuscados) {
        clearInterval(this.activeInterval);
        this.cargando = false;
        return;
      }

      try {
        this.resultadosBusqueda = JSON.parse(productosBuscados);

        if (this.resultadosBusqueda?.length) {
          clearInterval(this.activeInterval);
          const token = `${this.txtBuscador}${Math.floor(Math.random() * 10000000)}`;

          const datosBuscador = {
            token: token,
            buscado: this.txtBuscador,
            arrayBusqueda: JSON.stringify(this.resultadosBusqueda)
          };

          this.usuarioService.registraBuscador(datosBuscador).subscribe(() => {
            this.txtBuscador = "";
            localStorage.removeItem('productos_buscados');
            this.apiProductos.changeParam(this.resultadosBusqueda);
            this.router.navigate(['/resultados-busqueda', token]).then(() => {
              this.cargando = false;
            });
          });
        }
      } catch (e) {
        console.error('Error parsing search results:', e);
        clearInterval(this.activeInterval);
        this.cargando = false;
      }
    }, 1000);
  }

  /*
    mevoy() {
  
      this.resultadosBusqueda = JSON.parse(localStorage.getItem('productos_buscados') as any);
      this.cargando = true;
      var intervalopago = setInterval(() => {
  
  
        this.resultadosBusqueda = JSON.parse(localStorage.getItem('productos_buscados') as any);
  
  
  
        if (this.resultadosBusqueda != undefined) {
          clearInterval(intervalopago);
          var numeroAleatorio = Math.floor(Math.random() * (1 - 10000000 + 1)) + 1;
          var token = this.txtBuscador + numeroAleatorio;
  
          var datosBuscador = {
            "token": token,
            "buscado": this.txtBuscador,
            "arrayBusqueda": JSON.stringify(this.resultadosBusqueda)
          }
  
          this.usuarioService.registraBuscador(datosBuscador).subscribe(suscrito => {
  
            this.txtBuscador = "";
            localStorage.removeItem('productos_buscados');
            this.apiProductos.changeParam(this.resultadosBusqueda)
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.cargando = false;
              this.router.navigate(['/resultados-busqueda', token]);
  
            })
  
  
  
  
  
          });
  
        }
  
      }, 1000)
    }
  
    */

  checaVersion() {
    this.usuarioService.checaVersion().subscribe(version => {
      this.versionAppstore = version;

      if (this.versionAppstore > this.version) {
        this.errorVesion();

      }
    })
  }

  async errorVesion() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Nueva Version',
      message: 'Existe una nueva version de esta aplicación, te recomendamos actualizar para evitar errores ¿Deseas Actualizar ahora?',
      buttons: [
        {
          text: 'Confirmar',
          handler: () => {
            window.open("https://play.google.com/store/apps/details?id=io.ionic.lideart", '_system')
          },
        },
      ],
    });

    await alert.present();
  }


  openSearchBar() {
    var buscador = document.getElementById('buscador');
    if (buscador) {
      buscador.style.display = "block";
      /*setTimeout(() => {
        this.searchInput.nativeElement.focus();
        const event = new Event('touchstart');
        this.searchInput.nativeElement.dispatchEvent(event);
      }, 500);*/
    }
    this.txtBuscador = '';
    this.searchbarVisible = this.searchbarVisible == false ? true : false;
    if (this.searchbarVisible == false) {
      buscador!.style.display = "none";
    }
  }

  openSearchBar3() {
    var buscador = document.getElementById('asideMenu');
    this.searchbarVisible = this.searchbarVisible == false ? true : false;
    if (this.searchbarVisible == false) {
      buscador!.style.display = "none";
    }
  }

  openSearchBar2(item: any, sku: any) {

    var buscador = document.getElementById('buscador');
    if (buscador) {
      buscador.style.display = "block";
      this.cargando = true;
      this.apiProductos.esPaquete(sku).subscribe(paquetillo => {
        this.respaq = paquetillo;
        if (this.respaq == 1) {
          this.cargando = false;
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/descripcion-paquetes/' + item]);
          });
        }
        if (this.respaq == 0) {
          this.cargando = false;
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/descripcion-producto/' + item]);
          });
        }


      })


    }
    this.txtBuscador = '';
    this.searchbarVisible = this.searchbarVisible == false ? true : false;
    if (this.searchbarVisible == false) {
      this.cargando = true;
      buscador!.style.display = "none";
      this.apiProductos.getInfoPaquetes(sku).subscribe(paquetillo => {
        this.respaq = paquetillo;
        if (this.respaq == 1) {
          this.cargando = false;
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/descripcion-paquetes/' + item]);
          });
        }
        if (this.respaq == 0) {
          this.cargando = false;
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/descripcion-producto/' + item]);
          });
        }


      })

    }

  }

  search(event: any) {
    if (event.detail.value != '') {
      const filtro = (event.target as HTMLInputElement).value.trim();
      const fuse = new Fuse(this.productos, this.options);

      const pattern = filtro;
      this.resultadoBusqueda = fuse.search(pattern);
      this.numeroResultados = fuse.search(pattern).length;
      this.resultadoBusqueda = this.resultadoBusqueda.slice(0, 100);


    }
  }

  close() {
    // Clear any active interval
    if (this.activeInterval) {
      clearInterval(this.activeInterval);
    }

    this.menu.close();
    this.usuarioService.logout();
    this.authService.logout();
    this.router.navigateByUrl('/login', { replaceUrl: true });

    // Optional: Clear local storage items here if needed
    localStorage.removeItem('productos_buscados');

    setTimeout(() => {
      location.reload();
    }, 1000);
  }

  previousImage(producto: any) {
    if (producto.currentImageIndex > 0) {
      producto.currentImageIndex--;
    } else {
      producto.currentImageIndex = producto.imagenes.length - 1;
    }
  }

  // Navegar a la siguiente imagen
  nextImage(producto: any) {
    if (producto.currentImageIndex < producto.imagenes.length - 1) {
      producto.currentImageIndex++;
    } else {
      producto.currentImageIndex = 0;
    }
  }

  //   sendSms() {
  //     const numbers: string[] = ["+52 639 1061558"];
  //     SmsManager.send({
  //         numbers: numbers,
  //         text: "hola compadre, le mando este mensaje",
  //     }).then(() => {
  //         // success
  //     }).catch(error => {
  //         console.error(error);
  //     });
  // }

  closen() {
    this.menu.close();
  }


  checalo() {
    if (this.usuario != null) {
      this.usuarioService.checaUsuario(this.usuario.usuario).subscribe(data => {
        this.datosUsuario = data;

        this.nombre = this.datosUsuario.nombre;
        this.apellido = this.datosUsuario.apellido;
      });
    } else if (this.usuario == null) {
      this.nombre = 'Bienvenido a';
      this.apellido = 'Lideart';
    }
  }

  initMutationObserver() {
    if (isPlatformBrowser(this.platformId) && typeof MutationObserver !== 'undefined') {
      if (this.ulElement) {
        this.observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if (mutation.type === 'childList') {
              this.checkForListItems();
            }
          });
        });

        const config = { childList: true, subtree: true };
        this.observer.observe(this.ulElement.nativeElement, config);
      }
    }
  }

  checkForListItems() {
    if (this.ulElement) {
      const ul = this.ulElement.nativeElement;
      const hasListItems = ul.getElementsByTagName('li').length > 0;
      if (hasListItems) {
        console.log('UL element has <li> elements.');
        this.banderaLi = true;
      } else {
        console.log('UL element does not have <li> elements.');
      }
    }
  }

  closePopup() {
    this.showPopup = false;
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  toggleShowColors(event: MouseEvent, link: string): void {
    event.stopPropagation();
    event.preventDefault();
    if (this.coloresMostrados.includes(link)) {
      this.coloresMostrados = this.coloresMostrados.filter(itemLink => itemLink !== link);
    } else {
      this.coloresMostrados.push(link);
    }
  }
}
