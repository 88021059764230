import { CommonModule, isPlatformBrowser, Location } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router, RouterModule } from '@angular/router';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { IonButton, IonIcon } from '@ionic/angular/standalone';
import Fuse from 'fuse.js';

import Swal from 'sweetalert2';

import { Usuario } from '../../Models/usuario';
import { ApiProductosService } from '../../services/api-productos.service';
import { CarritoServiceService } from '../../services/carrito-service.service';
import { CheckoutServiceService } from '../../services/checkout-service.service';
import { FacebookApiService } from '../../services/facebook-api.service';
import { LoginService } from '../../services/login.service';
import { ServiciowishlistService } from '../../services/serviciowishlist.service';
import { TiktokEventsService } from '../../services/tiktok-events.service';
import { CartData } from '../../services/validar-inv.service';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    IonIcon,
    IonButton
  ],
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {
  public product: any;
  public searchbarVisible: boolean = false;
  public txtBuscador: string = '';
  public producto: any = [];
  usuario!: Usuario;
  public grandTotal!: number;
  public color!: string;
  public productos: any = [];
  totalItems: any;
  public resultadoBusqueda: any = null;
  compra = false;
  public numeroResultados: number = 0;
  public url: string = 'https://new.lideart.com.mx/';
  codigo: any;
  cupones: any = [];
  porcentaje: any;
  descuento = "no";
  mensaje: any;
  calculo: any;
  porcentajeFinal: any;
  cancelar = false;
  haydescuento = false;
  email: any;
  canjieLidepuntos = false;
  cancelarLidepuntos = false;
  cargando = false;
  lineas: any = [];
  lidepuntos: any = [];
  totalpuntos: any;
  paquetaxos: any = [];

  items: CartData[] = [];
  isLoadingCartItems: boolean = this.shoppingCart.isLoadingCarritoData();
  isAllItemAvaible: boolean = false;

  isAllItemAvailable(): boolean {
    return this.items.every(item => item.isAvaible && (item.qtyDisp ?? 0) >= item.quantity);
  }

  public options = {
    // isCaseSensitive: false,
    // includeScore: false,
    shouldSort: true,
    // includeMatches: false,
    // findAllMatches: false,
    minMatchCharLength: 5,
    // location: 0,
    threshold: 0.5,
    distance: 60,
    // useExtendedSearch: false,
    // ignoreLocation: false,
    // ignoreFieldNorm: false,
    // fieldNormWeight: 1,
    keys: ['nombre'],
  };

  datosUsuario: any = [];
  nombre!: string;
  apellido!: string;

  public toggleCantidad: number = 1;

  public isDesk: boolean = false;
  public isCel: boolean = false;

  public infoUser: any = [];
  public infoUser2: any = [];

  @ViewChild('mensaje_cupon') mensaje_cupon!: ElementRef<HTMLInputElement>;

  constructor(
    private apiProductos: ApiProductosService,
    public modalController: ModalController,
    public shoppingCart: CarritoServiceService,
    public toastController: ToastController,
    private checkoutService: CheckoutServiceService,
    private usuarioService: LoginService,
    public alertController: AlertController,
    private router: Router,
    private servicioWishlist: ServiciowishlistService,
    private location: Location,
    private titleService: Title,
    private apiFacebook: FacebookApiService,
    private renderer: Renderer2,
    private tiktokEvents: TiktokEventsService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {

    /*afterRender(()=>{
      this.usuarioService.usuario.subscribe(res =>{
        this.usuario = res;
        if (this.usuario) {
          this.checalo();
        }
      });
    })*/

  }

  ngOnInit() {
    this.titleService.setTitle("Carrito");
    if (isPlatformBrowser(this.platformId)) {

      if (window.innerWidth > 992) {
        this.isDesk = true;
      } else if (window.innerWidth <= 992) {
        this.isCel = true;
      }

      this.usuarioService.usuario.subscribe(res => {
        if (!res) return
        this.usuario = res;
        if (this.usuario) {
          this.usuarioService.checaUsuario(this.usuario.usuario).subscribe(data => {
            this.infoUser = data;
            //console.log(this.infoUser);
            this.checkoutService.getDireccion(this.usuario.usuario).subscribe(res => {
              this.infoUser2 = res;
              //console.log(this.infoUser2);

              this.apiFacebook.sendEvent('PageView', undefined, this.usuario.usuario, this.infoUser2[0].phone, {
                external_id: this.infoUser.customerID,
                nombre: this.infoUser2[0].firstname,
                apellido: this.infoUser2[0].lastname,
                estado: this.infoUser2[0].id_state,
                ciudad: this.infoUser2[0].city,
                country: 'México',
                codigo_postal: this.infoUser2[0].postcode
              });
            });
          });
          this.checalo();
          this.traePuntos();
        }
      });

      //Vuelve a consultar los productos en el carrito
      const _ = this.shoppingCart.getCartData();

    }

    this.shoppingCart.cartItems.subscribe(data => {
      if (data && Array.isArray(data)) {
        this.items = data;
        this.totalItems = data.length;
        this.getTotal(this.items);
      } else {
        this.items = [];
        this.totalItems = 0; // O cualquier valor por defecto que tenga sentido en tu contexto
      }
      this.isAllItemAvaible = !this.items.some(item => item.isAvaible === false);
    });
  }

  goBack() {
    this.location.back();
  }

  handleCantidad(p: number, i: number) {
    if (this.items[i].quantity <= 1 && p == -1) {
      return;
    }
    /*let qty : number;
    qty += p;*/
    let env = this.items[i].quantity;
    env += p;
    if (this.items[i].existencia < env) {
      this.ShowError();
    } else {
      this.QtyUpdated(env, i);
    }

    /*if (this.toggleCantidad <1) {
      this.toggleCantidad = this.items[i].quantity;
      return;
    }

    if(this.items[i].existencia < this.toggleCantidad){
      //console.log('no deberia');
      this.ShowError();
      this.toggleCantidad;
      this.compra = false;
    }else{
      //console.log('si deberia');
      this.QtyUpdated(this.toggleCantidad,i);
      this.compra= true;
    }*/
  }

  checalo() {
    this.usuarioService.checaUsuario(this.usuario.usuario).subscribe(data => {
      this.datosUsuario = data;


      this.nombre = this.datosUsuario.nombre;
      this.apellido = this.datosUsuario.apellido;
    })
  }

  openSearchBar() {
    var buscador = document.getElementById('buscador');
    if (buscador) {
      buscador.style.display = "block";
    }
    this.txtBuscador = '';
    this.searchbarVisible = this.searchbarVisible == false ? true : false;
    if (this.searchbarVisible == false) {
      buscador!.style.display = "none";
    }
  }



  search(event: any) {
    if (event.detail.value != '') {
      const filtro = (event.target as HTMLInputElement).value.trim();
      const fuse = new Fuse(this.productos, this.options);

      const pattern = filtro;
      this.resultadoBusqueda = fuse.search(pattern);
      this.numeroResultados = fuse.search(pattern).length;
      this.resultadoBusqueda = this.resultadoBusqueda.slice(0, 100);
    }
  }

  /*async openModal(id: number) {
    this.txtBuscador = '';

    this.apiProductos.getSpecificProduct(id).subscribe(async (response) => {
      if (response[0]) {
        this.product = response[0];
        //console.log(this.product);
        const modal = await this.modalController.create({
          component: ModalProductPage,
          cssClass: 'my-custom-class',
          initialBreakpoint: 0.6,
          breakpoints: [0, 0.6, 0.8, 1],
          componentProps: {
            product: this.product,
          },
        });
        return await modal.present();
      }
    });
  }*/

  getTotal(data: any) {
    let subs = 0;

    for (const item of data) {
      subs += item.precio * item.quantity;

      this.grandTotal = subs;

    }
  }

  onDelete(i: number) {

    if (this.cancelar == true || this.cancelarLidepuntos == true) {
      this.nopuedesborrar();
    } else {
      this.items.splice(i, 1);
      this.shoppingCart.setCartData(this.items);
      this.getTotal(this.items);
    }

  }

  validateInput(event: any, i: number) {
    const qty = + event.target.value;

    if (qty < 1) {
      event.target.value = this.items[i].quantity;
      return;
    }

    if (this.items[i].existencia < qty) {
      this.ShowError();
      event.target.value = this.items[i].quantity;
      this.compra = false;
    } else {
      this.QtyUpdated(qty, i);
      this.compra = true;
    }
  }

  private QtyUpdated(qty: number, i: number) {
    this.items[i].quantity = qty;
    this.shoppingCart.setCartData(this.items);
    this.getTotal(this.items);
  }

  vaciaCarrito() {
    this.shoppingCart.deleteCart();
  }

  async ShowError() {
    const toast = await this.toastController.create({
      message: 'No puedes agregar mas productos por disponibilidad',
      color: "danger",
      position: "top",
      duration: 1500

    });
    toast.present();
  }

  /*registraCarrito(){
    for (let item of this.items) {

      if (item.paquete == 1) {
        console.log("si es pack");
       this.apiProductos.getInfoPaquetes(item.sku).subscribe(paquetaxo =>{
        this.paquetaxos = paquetaxo;
        console.log(this.paquetaxos);
          for (let index = 0; index < this.paquetaxos.length; index++) {   
            let lineasLocal = {
              salesQuotationNumber: null,
              dataAreaId: "lin",
              itemNumber: this.paquetaxos[index].sku,
              requestedSalesQuantity: Number(this.paquetaxos[index].cantidad),
              shippingWarehouseId: "CHIHCONS",
              fixedPriceCharges: 0,
              salesTaxGroupCode: "VTAS",
              stF_Category: null,
              inventoryLotId: null,
              orderedSalesQuantity: 0,
              salesOrderNumber: null,
              descripcionLinea: item.title,
              usuario: null,
              salesPrice: Number(this.paquetaxos[index].precio_total),
              ShippingSiteId: "CHIH",
              LineDiscountPercentage: 0
            };
            this.lineas.push(lineasLocal);
            console.log(this.lineas);
          }  
        


       })
      }

      if (item.paquete != 1) {

          let lineasLocal = {
            salesQuotationNumber: null,
            dataAreaId: "lin",
            itemNumber: item.sku,
            requestedSalesQuantity: Number(item.quantity),
            shippingWarehouseId: "CHIHCONS",
            fixedPriceCharges: 0,
            salesTaxGroupCode: "VTAS",
            stF_Category: null,
            inventoryLotId: null,
            orderedSalesQuantity: 0,
            salesOrderNumber: null,
            descripcionLinea: item.title,
            usuario: null,
            salesPrice: Number(item.precio/1.16),
            ShippingSiteId: "CHIH",
            LineDiscountPercentage: 0
          };
    
          this.lineas.push(lineasLocal);
        
 
    }
    }
    this.cargando = true;
    var jsonCarrito = {"total": this.grandTotal, "email": this.usuario.usuario, "descuento": this.haydescuento,"codigo":this.codigo,"porcentaje":this.porcentaje,"contenido_carro":JSON.stringify(this.lineas),"carrito_guardado":JSON.stringify(this.items)};
    console.log(jsonCarrito);
    this.checkoutService.registraCarrito(jsonCarrito).subscribe(data=>{
      if (this.usuario) {
        this.email = this.usuario.usuario
      }else{
        this.email = "";
      }
        let datos = {
          "email": this.email
        }
        this.shoppingCart.carritoPasoCaja(datos).subscribe(data=>{
          this.cargando = false;
          this.router.navigate(['/checkout']);
        })
 
    })


  }*/

  async registraCarrito() {
    let total = 0;
    try {

      // Iterate over items in the cart
      for (let item of this.items) {
        if ('precio' in item) total += Number((item.precio * item.quantity).toFixed(2))
        // Check for description or description_short and set them to an empty string if they exist
        if ('description' in item) {
          item.description = '';
        }
        if ('description_short' in item) {
          item.description_short = '';
        }

        if (item.paquete == 1) {
          //console.log(this.items);

          // Fetch package info and add each item to the lineas array
          const paquetaxos: any = await this.apiProductos.getInfoPaquetes(item.sku).toPromise();
          for (let index = 0; index < paquetaxos.length; index++) {
            let lineasLocal = {
              salesQuotationNumber: null,
              dataAreaId: "lin",
              itemNumber: paquetaxos[index].sku,
              requestedSalesQuantity: Number(paquetaxos[index].cantidad),
              shippingWarehouseId: "CHIHCONS",
              fixedPriceCharges: 0,
              salesTaxGroupCode: "VTAS",
              stF_Category: null,
              inventoryLotId: null,
              orderedSalesQuantity: 0,
              salesOrderNumber: null,
              descripcionLinea: item.title,
              usuario: null,
              salesPrice: Number(paquetaxos[index].precio_total),
              ShippingSiteId: "CHIH",
              LineDiscountPercentage: 0
            };
            this.lineas.push(lineasLocal);
            // console.log(item.title);
          }
        } else {
          // For non-package items
          let lineasLocal = {
            salesQuotationNumber: null,
            dataAreaId: "lin",
            itemNumber: item.sku,
            requestedSalesQuantity: Number(item.quantity),
            shippingWarehouseId: "CHIHCONS",
            fixedPriceCharges: 0,
            salesTaxGroupCode: "VTAS",
            stF_Category: null,
            inventoryLotId: null,
            orderedSalesQuantity: 0,
            salesOrderNumber: null,
            descripcionLinea: item.title,
            usuario: null,
            salesPrice: Number(item.precio / 1.16),
            ShippingSiteId: "CHIH",
            LineDiscountPercentage: 0
          };
          this.lineas.push(lineasLocal);
        }
      }

      // Set loading state
      this.cargando = true;

      // Prepare the cart JSON object
      var jsonCarrito = {
        total: this.grandTotal,
        email: this.usuario ? this.usuario.usuario : "",
        descuento: this.haydescuento,
        codigo: this.codigo,
        porcentaje: this.porcentaje,
        contenido_carro: JSON.stringify(this.lineas),
        carrito_guardado: JSON.stringify(this.items)
      };
      //console.log(jsonCarrito);

      // Register the cart
      const response: any = await this.checkoutService.registraCarrito(jsonCarrito).toPromise();

      this.tiktokEvents.sendEvent({ event: 'InitiateCheckout', value: total });
      // proceed to checkout
      await this.shoppingCart.carritoPasoCaja({ email: this.email }).toPromise();
      this.router.navigate(['/checkout']);

    } catch (error) {
      console.error('Error registering the cart:', error);
      this.cargando = false; // Ensure loading is stopped even in case of an error
    } finally {
      this.cargando = false; // Ensure loading is stopped even in case of an error
    }
  }




  registraCarrito2() {
    let total = 0;
    for (let item of this.items) {
      if ('precio' in item) total += Number((item.precio * item.quantity).toFixed(2))

      if (item.paquete == 1) {

        this.apiProductos.getInfoPaquetes(item.sku).subscribe(paquetaxo => {
          this.paquetaxos = paquetaxo;

          for (let index = 0; index < this.paquetaxos.length; index++) {
            let lineasLocal = {
              salesQuotationNumber: null,
              dataAreaId: "lin",
              itemNumber: this.paquetaxos[index].sku,
              requestedSalesQuantity: Number(this.paquetaxos[index].cantidad),
              shippingWarehouseId: "CHIHCONS",
              fixedPriceCharges: 0,
              salesTaxGroupCode: "VTAS",
              stF_Category: null,
              inventoryLotId: null,
              orderedSalesQuantity: 0,
              salesOrderNumber: null,
              descripcionLinea: item.title,
              usuario: null,
              salesPrice: Number(this.paquetaxos[index].precio_total),
              ShippingSiteId: "CHIH",
              LineDiscountPercentage: 0
            };
            this.lineas.push(lineasLocal);
          }



        })
      }

      if (item.paquete != 1) {

        let lineasLocal = {
          salesQuotationNumber: null,
          dataAreaId: "lin",
          itemNumber: item.sku,
          requestedSalesQuantity: Number(item.quantity),
          shippingWarehouseId: "CHIHCONS",
          fixedPriceCharges: 0,
          salesTaxGroupCode: "VTAS",
          stF_Category: null,
          inventoryLotId: null,
          orderedSalesQuantity: 0,
          salesOrderNumber: null,
          descripcionLinea: item.title,
          usuario: null,
          salesPrice: Number(item.precio / 1.16),
          ShippingSiteId: "CHIH",
          LineDiscountPercentage: 0
        };

        this.lineas.push(lineasLocal);


      }
    }
    this.cargando = true;
    var jsonCarrito = { "total": this.grandTotal, "email": this.usuario.usuario, "descuento": this.haydescuento, "codigo": this.codigo, "porcentaje": this.porcentaje, "contenido_carro": JSON.stringify(this.lineas), "carrito_guardado": JSON.stringify(this.items) };

    this.checkoutService.registraCarrito(jsonCarrito).subscribe(data => {
      if (this.usuario) {
        this.email = this.usuario.usuario
      } else {
        this.email = "";
      }
      let datos = {
        "email": this.email
      }
      this.shoppingCart.carritoPasoCaja(datos).subscribe(data => {
        this.cargando = false;
        this.tiktokEvents.sendEvent({ event: 'InitiateCheckout', value: total });

      })

    })


  }

  traePuntos() {
    this.usuarioService.traePuntos(this.usuario.usuario).subscribe(data => {
      this.lidepuntos = data;
      this.totalpuntos = this.lidepuntos[0].Total_Lidepuntos;
      if (this.totalpuntos == null || this.totalpuntos == undefined) {
        this.totalpuntos = 0;

      }
    })

  }

  canjea5() {

    if (this.usuario) {
      this.cargando = true;
      var jsonCupon = { "codigo": 5, "email": this.usuario.usuario, "total": this.grandTotal.toFixed(2) };
      this.apiProductos.canjeaLidepuntos(jsonCupon).subscribe(codigos => {
        this.cupones = codigos;
        this.descuento = this.cupones.descuento;

        if (this.descuento == "si") {
          this.cargando = false;
          this.cancelarLidepuntos = true;
          this.canjieLidepuntos = true;
          this.porcentaje = 5;
          this.porcentaje = this.porcentaje / 100;
          this.calculo = this.grandTotal * this.porcentaje;
          this.grandTotal = this.grandTotal - this.calculo;
          this.haydescuento = true;
          this.sisearmo()
        }

      })
    }
  }

  canjea10() {

    if (this.usuario) {
      this.cargando = true;
      var jsonCupon = { "codigo": 10, "email": this.usuario.usuario, "total": this.grandTotal.toFixed(2) };
      this.apiProductos.canjeaLidepuntos(jsonCupon).subscribe(codigos => {
        this.cargando = false;
        this.cupones = codigos;
        this.descuento = this.cupones.descuento;

        if (this.descuento == "si") {
          this.cancelarLidepuntos = true;
          this.canjieLidepuntos = true;
          this.porcentaje = 10;
          this.porcentaje = this.porcentaje / 100;
          this.calculo = this.grandTotal * this.porcentaje;
          this.grandTotal = this.grandTotal - this.calculo;
          this.haydescuento = true;
          this.sisearmo()
        }

      })
    }
  }

  checamelcupon() {

    if (this.usuario) {
      var jsonCupon = { "codigo": this.codigo, "email": this.usuario.usuario, "total": this.grandTotal.toFixed(2) };
      this.apiProductos.checaCupon(jsonCupon).subscribe(codigos => {
        this.cupones = codigos;
        this.descuento = this.cupones.descuento;

        if (this.descuento == "si") {
          this.porcentaje = parseInt(this.cupones.porcentaje);
          this.porcentaje = this.porcentaje / 100;
          this.calculo = this.grandTotal * this.porcentaje;
          this.grandTotal = this.grandTotal - this.calculo;
          this.cancelar = true;
          this.haydescuento = true;
          this.sisearmo()
        }
        if (this.descuento == "no") {
          this.mensaje = this.cupones.mensaje;
          this.correoNoExiste();
        }

      })
    } else {
      this.nohayusuario()
    }

  }

  correoNoExiste() {
    this.renderer.setStyle(this.mensaje_cupon.nativeElement, 'display', 'flex');
    setTimeout(() => {
      this.renderer.setStyle(this.mensaje_cupon.nativeElement, 'display', 'none');
    }, 2500);
  }

  async sisearmo() {
    const alert = await this.alertController.create({
      cssClass: 'loginAlertComp',
      header: 'Exito',
      //subHeader: 'Subtitle',
      message: 'El cupon a sido aplicado',
      buttons: ['OK']
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();

  }

  async siloborrewe() {
    const alert = await this.alertController.create({
      cssClass: 'loginAlertComp',
      header: 'Exito',
      //subHeader: 'Subtitle',
      message: 'El cupon a sido cancelado',
      buttons: ['OK']
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();

  }

  navigateToDescripcionProducto(c: any, b: any, a: any, d: any, e: any) {
    const category = c; // Set the category dynamically
    const id_product = b; // Set the id_product dynamically
    const link_rewrite = a; // Set the link_rewrite dynamically
    const paquete = d; // Set the link_rewrite dynamically
    const sku = e; // Set the link_rewrite dynamically
    if (d == 1) {
      this.router.navigateByUrl(`/paquetes/${id_product}-${sku}` + ".html");
    } else {
      this.router.navigateByUrl(`/${category}/${id_product}-${link_rewrite}` + ".html");
    }
  }

  async nopuedesborrar() {
    const alert = await this.alertController.create({
      cssClass: 'loginAlertComp',
      header: 'error',
      //subHeader: 'Subtitle',
      message: 'Por favor cancela el cupon antes de borrar producto de carrito',
      buttons: ['OK']
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();

  }

  async nohayusuario() {
    const alert = await this.alertController.create({
      cssClass: 'loginAlertComp',
      header: 'error',
      //subHeader: 'Subtitle',
      message: 'Por favor inicia sesion antes de aplicar un cupon',
      buttons: ['OK']
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();

  }

  borramelcupon() {
    this.cargando = true;
    var jsonCupon = { "codigo": this.codigo, "email": this.usuario.usuario };
    this.apiProductos.borraCupon(jsonCupon).subscribe(cuponsito => {
      this.cargando = false;
      this.grandTotal = this.grandTotal + this.calculo;
      this.cancelar = false;
      this.haydescuento = false;
      this.descuento = "no";
      this.siloborrewe()
    })
  }

  borramelcupon2() {
    this.cargando = true;
    var jsonCupon = { "codigo": this.codigo, "email": this.usuario.usuario };
    this.apiProductos.borraCaneLidepuntos(jsonCupon).subscribe(cuponsito => {
      this.cargando = false;
      this.grandTotal = this.grandTotal + this.calculo;
      this.cancelarLidepuntos = false;
      this.canjieLidepuntos = false;
      this.haydescuento = false;
      this.descuento = "no";
      this.siloborrewe()
    })
  }

  agregarWishlist(id_product: any, title: any, sku: any, quantity: any, image_link: any, color: any, medida: any, precio: any) {
    var jsonWishlist = {
      "usuario": this.usuario.usuario,
      "id_product": id_product,
      "title": title,
      "sku": sku,
      "quantiity": quantity,
      "imagen": image_link,
      "sku_imagen": sku,
      "color": color,
      "medida": medida,
      "link": "",
      precio
    }
    this.servicioWishlist.sendWishlist(jsonWishlist).subscribe(data => {
      if (data == 0) {
        this.yaexiste();
      } else {
        this.agregado();
      }
    })

  }

  async yaexiste() {
    Swal.fire({
      icon: 'warning',
      text: 'Este Producto ya se encuentra en tu Wishlist',
    });
  }

  async agregado() {
    Swal.fire({
      icon: 'success',
      text: 'Este Producto se agrego a tu Wishlist.',
    });
  }

  async noagregado() {
    const toast = await this.toastController.create({
      message: 'Este Producto se agrego a tu Wishlist.',
      color: "success",
      position: "top",
      duration: 1500

    });
    toast.present();
  }

  async presentAlertConfirm(id_product: any, title: any, sku: any, quantity: any, image_link: any, color: any, medida: any, precio: any) {
    this.agregarWishlist(id_product, title, sku, quantity, image_link, color, medida, precio);
    // const alert = await this.alertController.create({
    //   cssClass: 'my-custom-class',
    //   header: 'Wishlist',
    //   message: '¿Deseas añadir a la lista de deseos?',
    //   buttons: [
    //     {
    //       text: 'Cancelar',
    //       role: 'cancel',
    //       cssClass: 'secondary',
    //       handler: (blah) => {
    //         // //console.log('Confirm Cancel: blah');
    //       },
    //     },
    //     {
    //       text: 'Confirmar',
    //       handler: () => {
    //       this.agregarWishlist(id_product, title, sku, quantity, image_link, color, medida, precio);
    //       },
    //     },
    //   ],
    // });

    // await alert.present();
  }
}
