<section class="contan-gen-gs p-4 md:pt-40 lg:pt-40" #focus>
  <div class="buscador-componente">
    <div class="left-controls">
      <div class="filter-container">
        <label for="resultsPerPage">Resultados por página:</label>
        <select id="resultsPerPage" [(ngModel)]="resultsPerPageForSelect" (change)="onResultsPerPageChange($event)">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="70">70</option>
          <option value="0">Todos</option>
        </select>
      </div>
    </div>
    <div class="right-controls">
      <div class="control-buscar">
        <input class="inp-busk-gs" [(ngModel)]="query" placeholder="Buscar..." id="gsc-i-id1"
          [ngModelOptions]="{standalone: true}">
        <button class="btn-busk-gs" type="submit" (click)="onSearch()" id="gsc-search-button-v2">Buscar</button>
      </div>
      <select class="select-buscar" [(ngModel)]="sortOrder" [ngModelOptions]="{standalone: true}">
        <option value="">Relevance</option>
        <option value="date">Date</option>
      </select>
    </div>
    <!-- <div class="buscador-titulo"></div> -->
  </div>

  <div *ngIf="results" class="resultados-busc">
    @if (customSearchService.isLoading()) {
    <div class="flex justify-center items-center flex-col w-full h-full">
      <img class="w-[150px] h-auto" src="/assets/img/loadersillo.gif">
      <p class="text-gray-400 font-normal text-[15px]">Cargando...</p>
    </div>
    } @else if (results && results.items && results.items.length > 0) {
    <a *ngFor="let item of results.items" class="prod-cont" [routerLink]="[ '/'+item.link ]">
      <div class="pp pp-cat" *ngIf="item.link.includes('/catalogo/') || item.link.includes('/categor')">
        <i class="fa-sharp fa-solid fa-layer-group"></i>
        <span class="txt-pp">Categoría</span>
      </div>
      <div class="pp pp-prod"
        *ngIf="!item.link.includes('/catalogo/') && !item.link.includes('/paquetes/') && !item.link.includes('/categor')">
        <i class="fa-sharp fa-solid fa-tags"></i>
        <span class="txt-pp">Producto</span>
      </div>
      <div class="pp pp-paq" *ngIf="item.link.includes('/paquetes/')">
        <i class="fa-sharp fa-regular fa-boxes-stacked"></i>
        <span class="txt-pp">Paquete</span>
      </div>
      <div class="img-cont-prod">
        <img class="img-prod" *ngIf="item.pagemap?.cse_thumbnail?.[0]?.src"
          [src]="item.pagemap?.cse_thumbnail?.[0]?.src" alt="{{ item.title }}" [attr.loading]="'lazy'"
          (load)="onImagesLoaded()">
      </div>
      <div class="txt-cont-prod">
        <span class="title-prod">{{ item.title }}</span>
      </div>

      <!-- <div class="line-tb">
        <div *ngIf="item && item.variables && item.variables.length > 1" class="colors-container">
          <div
            *ngFor="let variable of (coloresMostrados.includes(item.link) ? item.variables : item.variables?.slice(0, 10)) ?? []"
            class="r-color"
            [ngStyle]="{'background-color': variable.color, 'background-image': variable.image ? 'url(' + variable.image + ')' : 'none'}">
          </div>
          <div *ngIf="(item.variables?.length ?? 0) > 10 && !coloresMostrados.includes(item.link)"
            class="r-color more-colors" (click)="toggleShowColors($event, item.link)">
            ...
          </div>
          <div *ngIf="coloresMostrados.includes(item.link)" class="r-color more-colors"
            (click)="toggleShowColors($event, item.link)">
            ↑
          </div>
        </div>
      </div> -->
      <!--<div class="rowins">
          <div class="desc-cont-prod">
            {{ item.snippet }}
          </div>
        </div>-->
      <div class="rowins">
        <!--<div class="link-cont-prod">
            <a class="btn-linkis">Ver</a>
          </div>-->
        <div class="price-cont-prod"
          *ngIf="item.pagemap.metatags[0].productPrice && item.pagemap.metatags[0].productPrice && item.pagemap.metatags[0].productPrice > 0">
          @if (item.pagemap.metatags[0].productDescuento && item.pagemap.metatags[0].productPrice) {
          <span class="price-strikethrough">
            $ {{ item.pagemap.metatags[0].productPrice | number: '1.2-2' }}
          </span><br>
          <span class="price-prod">$ {{ (+item.pagemap.metatags[0].productPrice) * (1 -
            +item.pagemap.metatags[0].productDescuento) | number: '1.2-2' }} <span class="curr">MXN</span></span>
          } @else {
          <span class="price-prod">$ {{ item.pagemap.metatags[0]['productPrice'] | number: '1.2-2' }} <span
              class="curr">MXN</span></span>
          }
          <!-- <span class="price-prod">{{ items.descuento }} <span class="curr">MXN</span></span> -->
        </div>
      </div>
    </a>
    } @else {
    <div class="flex justify-center items-center flex-col w-full h-full">
      <i class="fa-light fa-face-sad-sweat text-[#1ca3c9] text-[35px] mb-1"></i>
      <p class="text-gray-400 font-normal text-[15px]">No se encontraron resultados</p>
    </div>
    }
    @if (results && results.items && results.items.length > 0 && !this.isTodos) {
    <div class="btns-cont">
      <button *ngIf="currentPage > 1" class="prev-forw-btns" (click)="prevPage()">Atrás</button>
      <button *ngIf="(currentPage * resultsPerPage) < allItems.length" class="prev-forw-btns"
        (click)="nextPage()">Siguiente</button>
    </div>
    }
  </div>
  <div class="filter-container">
    <label for="resultsPerPage">Resultados por página:</label>
    <select id="resultsPerPage" [(ngModel)]="resultsPerPageForSelect" (change)="onResultsPerPageChange($event)">
      <option value="10">10</option>
      <option value="20">20</option>
      <option value="50">50</option>
      <option value="70">70</option>
      <option value="0">Todos</option>
    </select>
  </div>
</section>