import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor(public http: HttpClient) { }

  validarCorreo(correo: any) {
    return this.http.get<Response>('https://new.lideart.com.mx/Automatizacion/apis/emailValidation.php?email=' + correo);
  }
}
